import React from "react";
import styles from "./style.module.scss";
import clsx from "clsx";
import {IconArrowDown,} from "../../assets";
import UsefullFor from "./UsefullFor/UsefullFor";
import VideoWrapper from "./video-wrapper/VideoWrapper";
import Poster from "./poster/Poster";
import {CommonTitle} from "./common-title/CommonTitle";

export const Banner = () => {
  const videos = [
    {
      title: "Once again, what is it?",
      description: "\"JUST DO LUCK\" is a unique prize draw marketing platform that provides businesses with a dynamic way to capture people's attention, generate buzz around their brand, and cultivate interest in their offerings and ultimately drive sales and revenue.",
      link: "https://www.youtube.com/watch?v=gJ_BB38zMBA"
    },

    {
      title: "What is the service for?",
      description: "We help businesses improve metrics like conversions, lead generation, loyalty, traffic, and sales, while also delivering a fun and entertaining, memorable experience for users.",
      link: "https://www.youtube.com/watch?v=reB9Au5yW3c"
    },

  ]
  return (
      <div>
        <section className={styles.banner} id="banner">
          <CommonTitle/>
      </section>
      <section className="container container--mob-nopad">
        <div className={styles.white}>
          <span className={clsx(styles.scroll, "animated wow bounce")}>
            <IconArrowDown/>
          </span>
          <div className={clsx(styles.heading)}>
            <Poster/>
            <h2 className={clsx(styles.title, styles.title_colored, "animated fadeIn wow")}>
              What is service «JUST&nbsp;DO&nbsp;LUCK»?
            </h2>

            <div className={clsx(styles.description, "animated fadeIn wow")}>
              JDL's groundbreaking technology offers a one-of-a-kind gaming experience that enables empowers
              participants of a draw to accurately determine the luckiest player from their group.
            </div>


            <h2 className={clsx(styles.title, styles.title_colored, "animated fadeIn wow")}>
              Who can use the JDL service?
            </h2>

            <UsefullFor/>
            {videos.map(v => <VideoWrapper {...v} />)}
          </div>
        </div>
      </section>
    </div>
  );
};
