import posterImage from "../../../assets/image/banner/poster.svg"
import style from "./style.module.scss"

const Poster = () => {
    return <div className={style.wrapper}>
        <div className={style.image}>
            <img src={posterImage} alt=""/>
        </div>
        <div className={style.text}>
            Our goal is to capture and engage people's attention and emotions by offering exciting draw prizes that resonate with them on an emotional level and grab their interest.
        </div>
    </div>
}
export default Poster