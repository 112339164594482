import style from "./style.module.scss"

interface ICard{
    image: string,
    title: string,
    text: string
}

export const Card = (props: ICard)=>{
    return <div className={style.card_wrapper}>
        <div className={style.card_image}>
            <img src={props.image}/>
        </div>
        <div className={style.card_title}>
            {props.title}
        </div>
        <div className={style.card_text}>
            {props.text}
        </div>
    </div>
}
