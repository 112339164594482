import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";
import clsx from "clsx";
import {Accordion} from "../accordion/Accordion";
import Wiki from "../wiki/Wiki";
import EnvController from "../../utils/EnvController";


export const Faq = () => {
  const [items, setItems] = useState([])
  useEffect(()=>{
    (async ()=>{
      const responce = await fetch(`${EnvController.getEnvController().wayToApi}game/public-scope/common/landing-faq`, {
        headers: {
          "Api-Key": "justdoluck_api"
        }
      })
      const json = await responce.json();
      let fixed = json.data.landingFaq.map((v: any)=>{
        return {
          title: v.question,
          body: v.answer
        }
      })
      setItems(fixed)
    })()
  }, [])
  return (
    <div className={styles.faq} id="faq">
      <div className="container">
        <div className={styles.heading}>
          <h2 className="h2 text-center animated wow fadeInDown">FAQ</h2>
        </div>
        <div
          className={clsx(styles.wrapper, "animated wow bounceInUp")}
          data-wow-offset="50"
          data-wow-delay="0.2s"
        >
          <Accordion data={items} />
        </div>
      </div>
      <Wiki/>
    </div>
  );
};
