import styles from "../style.module.scss";
import clsx from "clsx";
import {bImage1, bImage2, bImage3, bImage4, bImage5} from "../../../assets";
import React from "react";

const images = [
    {
        style: styles.img1,
        image: bImage1,
        delay: '0.5s',
        dataDepth: 0.3
    },
    {
        style: styles.img2,
        image: bImage2,
        delay: '0.3s',
        dataDepth: 0.5
    },
    {
        style: styles.img3,
        image: bImage3,
        delay: '0.6s',
        dataDepth: 0.4
    },
    {
        style: styles.img4,
        image: bImage4,
        delay: '0.5s',
        dataDepth: 0.6
    },
    {
        style: styles.img5,
        image: bImage5,
        delay: '0.3s',
        dataDepth: 0.4
    },

]

export const CommonTitle = () => {
    return <div className="container ">
        <div className={styles.images}>
            {images.map(v => {
                return <span
                    className={clsx(styles.img, v.style, "prl wow bounceInDown")}
                    data-wow-delay={v.delay}
                >
              <img src={v.image} alt="" data-depth={v.dataDepth}/>
            </span>
            })}
        </div>
        <h1 className={clsx(styles.h1, "animated fadeIn wow")}>
            The modern era places great importance on the ability to capture attention
            and elicit emotional responses
        </h1>
    </div>
}