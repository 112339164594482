import style from "./style.module.scss"
import {Card} from "./Card";
import cardImage1 from "../../../assets/image/like.png"
import cardImage2 from "../../../assets/image/sskoo.png"
import cardImage3 from "../../../assets/image/view.png"

import floatingImage1 from "../../../assets/image/like1.png"
import floatingImage2 from "../../../assets/image/sskoo1.png"
import floatingImage3 from "../../../assets/image/view1.png"

const cards = [
    {
        image: cardImage1,
        title: "Increase the effectiveness of advertising campaigns",
        text: "by strengthening associative\n" +
            "ties and increasing involvement in social networks, websites, sales platforms\n"
    },
    {
        image: cardImage2,
        title: "Reduce the cost of attracting customers",
        text: "Reduce the cost of attracting a client by having gamification and interactivity tools"
    },
    {
        image: cardImage3,
        title: "Build the system\n" +
            "viral marketing",
        text: "Build a viral marketing system using emotional response and audience loyalty"
    }
]

const HowHelp = () => {


    return <div>
        <div className={style.title}>
            How will Just Do Luck help you?
        </div>
        <div className={style.cards_wrapper}>
            {cards.map(v => <Card {...v}/>)}
        </div>
        <div className={style.floating_icons}>
            <img src={floatingImage1} alt=""/>
            <img src={floatingImage2} alt=""/>
            <img src={floatingImage3} alt=""/>
        </div>
    </div>
}
export default HowHelp