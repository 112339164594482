import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";
import {White} from "../ui/white/White";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Articles} from "../articles";
import {articles, cases} from "../../mock";
import {News} from "../News";
import {Faq} from "../faq";
import ReactTooltip from "react-tooltip";
import {CalcExample} from "./CalcExample";
import EnvController from "../../utils/EnvController";
import {Cases} from "../cases";
import {PriceTableDesktop} from "./price-table/PriceTableDesktop";
import {PriceTableMobile} from "./price-table/mobile/PriceTableMobile";


export const Pricing = () => {

  const [calcExaple, setCalcExample] = useState(false);

  useEffect(() => {
    if (window.location.href.includes("#cases")) {
      const nav = document.getElementById("header-cases-link");
      setTimeout(() => {
        nav!.click();
      }, 1000)
    }
  }, [])

  const [servicePackets, setServicePackets] = useState<any>([])
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let responce = await fetch(`${EnvController.getEnvController().wayToApi}customer/public-scope/service-packages`, {
          headers: {
            "Api-Key": "justdoluck_api"
          }
        });
        let json = await responce.json();
        if (json.success) {
          setServicePackets(json.data.servicePackages as any);
          console.log(json.data)
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    })();
  }, [])

  if (isLoading) {
    return <div>
      Loading...
    </div>
  }
  return (
      <div className="container">
        <CalcExample open={calcExaple} onClose={() => setCalcExample(false)}/>
        <White>
          <div className={styles.container}>
            <div className={styles.heading} id="pricing">
              <h2 className={styles.title}>Pricing </h2>
            </div>
            <ReactTooltip
                id="tbl"
                place={"top"}
                type={"dark"}
                effect={"float"}
                multiline={true}
                borderClass="tooltip-border"
                className="tooltip2"
                arrowColor="#97abc0"
            />

            <PriceTableDesktop servicePackets={servicePackets} setCalcExample={setCalcExample}/>
            <PriceTableMobile servicePackets={servicePackets} setCalcExample={setCalcExample}/>

            <div className={styles["button-wrap"]}>
              <a href="https://justdoluck.com" className={styles.button} target={"_blank"}>
                Go to the prize draw platform
              </a>
            </div>

            <div id="cases">
              <Cases cases={cases} title="Cases" />
            </div>
            <div id="articles">
              <Articles articles={articles} title="Articles" />
            </div>
          <div id="news">
            <News />
          </div>
          <div className={styles.faq} id="faq">
            <Faq />
          </div>
        </div>
      </White>
    </div>
  );
};
