import React, {FC} from "react";
import Modal from "../../modal";
import styles from "./styles.module.scss";
import clsx from "clsx";
import modal1 from "../../../assets/image/article/modal1.jpg";
import modal1_1 from "../../../assets/image/article/modal1_1.jpg";
import modal2 from "../../../assets/image/article/modal2.jpg";
import modal2_1 from "../../../assets/image/article/modal2_1.jpg";
import modal3 from "../../../assets/image/article/modal3.jpg";
import modal4 from "../../../assets/image/article/modal4.jpg";
import modal5 from "../../../assets/image/article/modal5.jpg";

type Props = {
  open: boolean;
  onClose: () => void;
  id: number;
};

export const ArticleModal: FC<Props> = ({ open, onClose, id }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.modal}>
        {id === 0 ? (
          <>
            <p className={styles.date}>11/15/2022</p>
            <h3 className={styles.title}>Why do we create JUST DO LUCK?</h3>
            <p className={styles.text}>
              To create something big, new, necessary, needed, useful,
              interesting and emotional. To leave a mark in the history of
              mankind, as talented people before us did and will do after. We
              believe and know that we are creating a service that will change
              the idea of what is possible and make people's lives better. The
              spiraling dynamics of chaotic reality give us a chance to have a
              small impact on improving the global value distribution model,
              which we are trying to take advantage of for the benefit of all
              people. Equality of all and absolute justice are the fundamental
              ideology of{" "}
              <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                  JUST DO LUCK
              </a>
              . These truths are inalienable and basic.
            </p>

            <div className={styles.border}>
              <p className={styles.border__text}>
                The equality of human beings is proclaimed in the scriptures of
                all religions. The universally recognized principle of equality
                is declared in the preamble of the constitutional documents of
                most countries. In the U.S. Declaration of Independence there
                are lines "We hold these truths to be self-evident, that all men
                are created equal, that they are endowed by their creator with
                certain unalienable rights, that among these are life, liberty
                and the pursuit of happiness....". The situation is the same in
                the European Constitution. We conclude with full confidence that
                "the equality of men and justice" are universal values
                recognized all over the world at the state level, and confirmed
                by documents of supreme legal force.
              </p>
            </div>
            <p className={styles.text}>
              Legally all are equal, but the actual are not - there is a lack of
              an open, accessible "mechanism" in which people can take advantage
              of the absolute equality of their opportunities{" "}
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    here and now.
                </a>
            </p>
            <img src={modal1} alt="" className={styles.img} />
            <h4 className={styles.subtitle}>
              And what does each person need "here and now"?
            </h4>
            <p className={styles.text}>
              One way of answering the question can be seen as a simple
              opportunity for a person to safely, simply and legally influence
              the next minute of his life with an easy effort of will and action
              and without material restraints to obtain an absolute trusting
              environment with additional possibilities. <br />
              <br />
              Out of this emerged the first goal of JUST DO LUCK - to create a
              "working model of absolute equality. At first it smelled utopian,
              but then the fog dissipated and a harmonious, beautiful idea
              emerged, which after comprehensive elaboration and testing turned
              into a clear and precise business model.
            </p>
            <img src={modal1_1} alt="" className={styles.img} />
            <h5 className={styles.subtitle2}>
              Now, to answer the eternal question - <span>what for?</span>
            </h5>
            <ul className={styles.ul}>
              <li>
                To realize equality in its absolute form and give people equal
                additional opportunities to use their contemporary values:
                micro-time, micro-attention, micro-choice and micro-action.
                <li>
                  That people would redefine equality of opportunity and
                  immediately exercise their inalienable right to it.
                </li>
                <li>
                  That anyone could use choice as a manifestation of free will
                  in their human nature and be absolutely certain that
                  everything is fair and just.
                </li>
                <li>
                  That a fundamentally different way of thinking and acting
                  would be created.
                </li>
                <li>
                  What would create a fair mechanism for the realization of
                  intuitive, pure randomness in society.
                </li>
                <li>
                  That there would be a pleasant means of entertainment capable
                  of igniting hope, giving new emotions, experiencing freedom,
                  and seeing the wonderful in simple choices.
                </li>
                <li>
                  That everyone would have a "fulcrum" that gives everyone equal
                  power to control a piece of their destiny (the storyline of
                  life) and come to the realization that equal choice and equal
                  opportunity is always "at hand.
                </li>
                <li>
                  To prove that it is possible to create a self-developing
                  ecosystem in which the distribution of values is based on the
                  principles of absolute justice for people.
                </li>
              </li>
            </ul>
            <div className={styles.line}>
              <div className={styles.round}></div>
            </div>
            <p className={styles["text--small"]}>
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    JUST DO LUCK
                </a>
              &nbsp; is a simple game of "eagle and tails", but on a scale. The
              game is not between two people, but between many people who join
              together in play groups and jointly participate in a raffle at
              the same time according to the same rules with equal chances and
              opportunities.
            </p>
            <br />
            <br />
            <p className={styles["text--small"]}>
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    JUST DO LUCK
                </a>
              &nbsp; is an experiment that reveals the beauty of pure chance.
            </p>
          </>
        ) : null}
        {/* 2 */}

        {id === 1 ? (
          <>
            <p className={styles.date}>11/18/2022</p>
            <h3 className={styles.title}>JUST DO LUCK service axis</h3>
            <p className={styles["text-grad"]}>
              Everything in the world twists and turns on its axis...
            </p>
            <p className={styles.text}>
              The axis of the JUST DO LUCK project is the dual (dual) nature of
              the world and man around which the innovative service of equal
              opportunities is woven.{" "}
            </p>
            <br />
            <p className={styles.text}>
              <b>Human beings by their psychological nature are dual</b> and
              most of the time are in a constant choice between two
              "possibilities": to do - not to do; to try - not to try; to be -
              not to be, etc. This duality is a sacred property of human psyche
              and physiology, reflecting the most general patterns.
              Uninhibitedly and easily choosing hot or cold, white or black,
              heads or tails, a person consciously lives and develops.
            </p>
            <div className={styles.border}>
              <p className={styles.border__title}>
                In essence, life is a choice
              </p>
            </div>
            <p className={styles.text}>
              Choice, as a form of development, is natural, understandable and
              pleasing to man. It is the axis around which his life revolves.
              The right and will of choice is the fundamental moral and social
              reality of man.
            </p>
            <br />
            <p className={styles.text}>
              <b>Natural duality</b> is a manifestation of duality in the world.
              The world is dual initially in our consciousness. Our entire
              reality is woven of contradictions, complementing each other: Yin
              - Yang, light - darkness, extraversion - introversion, activity -
              passivity, harmony - chaos, illusion - reality. The dual nature of
              our world manifests itself everywhere: there is left and right,
              there is up and down, soul and mind, living and dead. Duality is
              everywhere and in everything. Every physical body in our world is
              the result of a combination of dualities: energy and matter.
              <br />
              <br />
              Everything in the world has its polarities and opposites, i.e.
              reality, in all its diversity, has a well-defined mirror symmetry.
            </p>
            <br />
            <br />
            <p className={styles.text}>
              <b>The development of digital technology</b> began with binary
              logic.
            </p>
            <img src={modal2} alt="" className={styles.img} />
            <h4 className={styles.subtitle}>
              And what does each person need "here and now"?
            </h4>
            <p className={styles.text}>
              In the 17th century, the German scientist Gottfried Leibniz
              reduced logic to mathematics. He noted the particular simplicity
              of algorithms for arithmetic operations in binary arithmetic.
              <br />
              <br />
              In the 19th century the Englishman George Bull created a new field
              of science - mathematical logic. The essence of which is that a
              statement can be represented by some variable, the value of which
              can only be false (0) or true (1).
              <br />
              <br />
              100 years later, the American engineer and mathematician Claude
              Shannon found a remarkable application of the binary system in the
              design of electronic systems. The relative ease of creating
              electronic circuits with two electrical states and led to the fact
              that the binary representation of numbers dominates modern digital
              technology and eventually became the basis for all computer
              calculations.
              <br />
              <br />
              The proposed duality of choice (heads or tails), namely the choice
              of one of two equally possible and equally probable variants of a
              regularly repeated random binary event is the JUST DO LUCK axis!
            </p>
            <img src={modal2_1} alt="" className={styles.img} />
            <h4 className={styles.subtitle2}>
              Let's look at it from a different angle:
            </h4>
            <p className={styles.text}>
              Just Do Luck is <span>simple.</span> Everyone makes their choice,
              heads or tails (1st duality). The choice is simple and natural,
              fitting seamlessly into everyday life. By and large, the "binary"
              choice is the only freedom we have.
              <br />
              <br />
              Just Do Luck is <span>understandable.</span> Choosing one of two
              equally possible and equally likely options. There is no third
              choice. There is always a 50% to 50% chance of winning/losing in a
              raffle round. In the combination of choices made by participants in
              the game group, the initial group is divided into two groups
              (bifurcation occurs). One group is united by the common choice of
              " eagle ", the second group by " tails " (2nd bifurcation).
            </p>
            <br />
            <br />
            <p className={styles["text--small"]}>
              If his choice of the "coin flip" option and the actual result of
              the "coin flip" generated by the JDL algorithm coincide, the
              participant keeps playing; if not, he leaves the playing group,
              which continues the game (the 3rd duality).
              <br />
              <br />
              Each "coin flip" is independent, meaning that previous flips do
                not affect the results of subsequent flips. Each time, this
                randomness always has only one probability, 50% to 50% (4th
                Duality). "Coin Flips" - generated by the JDL algorithm, which
                produces one of two equally possible and equally probable "even or
                odd" values every minute, which are tied to the game logic as
                follows: even-eagle, odd-tails (5th duality). <br/>
                <br/>
                This is such a beautiful harmony of the project JUST DO LUCK - the
                universality of the law of unity and struggle of opposites.
            </p>
              <div className={styles.line}>
                  <div className={styles.round}></div>
              </div>
              <p className={styles["text--small"]}>
                  <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                      JUST DO LUCK
                  </a>
                  &nbsp; is a simple game of "eagle and tails", but on a scale. The
                  game is not between two people, but between many people who join
                  together in play groups and jointly participate in a raffle at
                  the same time according to the same rules with equal chances and
                  opportunities.
              </p>
              <br/>
              <br/>
              <p className={styles["text--small"]}>
                  <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                      JUST DO LUCK
                  </a>
                  &nbsp; is an experiment that reveals the beauty of pure chance.
              </p>
          </>
        ) : null}
        {/* 3 */}

        {id === 2 ? (
          <>
            <p className={styles.date}>11/21/2022</p>
            <h3 className={styles.title}>
              Probabilities and odds in JUST DO LUCK
            </h3>
            <p className={styles.text}>
              People often think in probabilistic terms. Simply because our world
              is set up in such a way that every event occurs with some degree
              of probability. In JUST DO LUCK there are always equal
              probabilities and chances for everyone to win, and everyone always
              has an equal probability of making the right choice.{" "}
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    Play
                </a>{" "}
              and see for yourself
            </p>
            <br />
            <br />
            <p className={styles.text}>
              Probability - a mathematical concept. In this case, it's a classic
              example: flipping a coin. It could be heads or tails. Since there
              are only two sides to a coin, there is always a ½ or 50% chance of
              " eagle " or " tails " falling out.
              <br />
              <br />
              Let us immediately explain that the logic of JUST DO LUCK is based
              on a model where the previous coin flip, which was before the next
              event - it does not matter, ie they are not related. This is a
              very important point, which you need to learn to understand and
              not to get into a cognitive distortion of thinking, the so-called
              "player error".
            </p>
            <br />
            <br />
            <p className={clsx(styles["text--italic"], styles.text)}>
              It happened in Monaco in 1913, when one of the casinos in Monte
              Carlo had an incredible event. At the same gaming table the ball
              stopped 26 times in a row on the black field, which had never
              happened in the entire five-century history of the casino. Of
              course, the players bet more and more confidently on the red field
              - and lost time after time, never ceasing to be amazed. After all,
              it seemed impossible! <br />
              <br />
              The gambler's error reflects a widespread misunderstanding of the
              randomness of events. It is connected with the fact that, as a
              rule, a person is not intuitively aware of the fact that the
              probability of the desired outcome does not depend on the previous
              outcomes of a random event. It seems to him that if a random event
              has already happened in the past, the probability of its
              occurrence in the future is reduced, although this is not so.
              <br />
              <br />
              We flip a coin nine times, and all times it comes out as an eagle.
              What is the probability that the next time an eagle will fall
              again? For many people, it seems obvious that the next time we
              toss a coin, the probability of "tails" will be greater: it is
              hard to believe that "eagle" can fall for the tenth time in a row.
              Nevertheless, this conclusion is erroneous. This probability would
              be ½ or 50%, exactly the same as all the previous times. And it
              doesn't matter that the eagle has fallen nine times before. The
              mistake most people make is to misunderstand the randomness of
              events and to believe that the probability of the next outcome
              somehow depends on the previous ones. No! Every event generated by
              the JUST DO LUCK algorithm and displayed on the main timeline is
              independent of all others. Even if an eagle is struck a million
              times in a row, there is still a 50% chance of another eagle being
              struck.
            </p>
            <img src={modal3} alt="" className={styles.img} />
            <h4 className={styles.subtitle2}>It's simple:</h4>
            <p className={styles.text}>
              The "eagle or tails" coin toss is a classic example of a random
              process with an equally likely outcome:
            </p>
            <br />
            <br />
            <p className={styles.text}>
              <strong></strong>
              Round 1 is 50%/50%. Passage in Round 2 is 50%.
            </p>
            <br />
            <p className={styles.text}>
              <strong>Round 2</strong> is 50%-50%. Passage in Round 3 is 25%.
            </p>
            <br />
            <p className={styles.text}>
              <strong>Round 3</strong> - 50% 50%. Passage in Round 4 - 12.5%
            </p>
            <br />
            <p className={styles.text}>
              <strong>Round 4</strong> - 50% for 50%. Passage in Round 5 - 6.25%
            </p>
            <br />
            <p className={styles.text}>
              <strong>Round 5</strong> - 50% at 50%. Passage in Round 6 - 3.125%
            </p>
            <br />
            <p className={styles.text}>
              <strong>Round 6</strong> - 50% at 50%. Passage in Round 7 -
              1.5625%.
            </p>
            <br />
            <p className={styles.text}>
              <strong>Round 7</strong> - 50% at 50%. Passage in Round 8 -
              0.78125%
            </p>
            <br />
            <p className={styles.text}>
              <strong>Round 8</strong> - 50% 50%. Passage in Round 9 - 0.390625%
            </p>
            <br />
            <p className={styles.text}>
              <strong>Round 9 </strong> - 50% 50%. Passage in Round 10 -
              0.1953125%
            </p>
            <br />
            <p className={styles.text}>
              <strong>Round 10 </strong> - 50% 50%. Passage in Round 11 is
              0.09765625%, etc.
            </p>
            <br />
            <br />
            <h4 className={styles.subtitle2}>
              Probability, the same for all values:{" "}
            </h4>
            <p className={styles.text}>
              - in the moment, always 50%; <br />
              <br />- in dynamics, with a sequential tie from the first to the
              next choice: 50%; - 25%; - 12.5%; - 6.25%; - 3.125%; etc.
            </p>
            <br />
            <br />
            <p className={styles.text}>
              <b>Conclusion 1</b> - the probability of the next heads or tails
              is still 50% or ½. So there is no point in following the previous
              coin flip results, the probability of those numbers falling out
              still does not change and is 50%.
            </p>
            <br />
            <p className={styles.text}>
              <b>Conclusion 2</b> - the odds of winning are equal for all
              participants in the raffle! In the first round they are 50%-50%, in
              the second round they are 50%-50%, in the third round they are
              50%-50% again, etc. Don't get caught up in player error. The
              theory of probability is inexorable, and that's the beauty of it.
            </p>
            <br />
            <p
              className={styles["text--small"]}
              style={{ fontStyle: "italic", color: "#6C737D " }}
            >
                P.S. It should be understood that the probability of falling eight
                tails to a row is ½, as we already know that the next throw is
                independent of the previous one, but the fall of eight tails in a
                row, i.e. the combination T-T-T-T-T-T-T, (where T-tails, and,
                E-eagle) event is unlikely, namely 1/256. Again, this is correct!
                Because the probability of any predetermined combination of heads
                and tails will be the same and equal, in particular, to 1/256 for
                the case of eight coins. It's just that all of the eagles or tails
                are memorable and kind of "notable" events.
            </p>
              <div className={styles.line}>
                  <div className={styles.round}></div>
              </div>
              <p className={styles["text--small"]}>
                  <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                      JUST DO LUCK
                  </a>
                  &nbsp; is a simple game of "eagle and tails", but on a scale. The
                  game is not between two people, but between many people who join
                  together in play groups and jointly participate in a raffle at
                  the same time according to the same rules with equal chances and
                  opportunities.
              </p>
              <br/>
              <br/>
              <p className={styles["text--small"]}>
                  <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                      JUST DO LUCK
                  </a>
                  &nbsp; is an experiment that reveals the beauty of pure chance.
              </p>
          </>
        ) : null}

        {/* 3 */}
        {id === 3 ? (
          <>
            <p className={styles.date}>11/24/2022</p>
            <h3 className={styles.title}>
              Maximum transparency and impeccable honesty JUST DO LUCK
            </h3>
            <p className={styles.text}>
              Transparency, honesty, fairness define the phenomenon of public
              trust in any online service. Trust is a new form of social
              capital. In the online business, stable trust is the most reliable
              component of success and leadership.
            </p>
            <br />
            <p className={styles.text}>
              The strategic goal of the{" "}
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    JUSTDOLUCK
                </a>{" "}
              project is to earn a high level of trust from both users and
              businesses. For us, "trust" is a prerequisite for unlocking an
              exponential growth model and is an imperative of the highest
              order.
              <br />
              <br /> Trust has power. The power of{" "}
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    JUSTDOLUCK
                </a>{" "}
              was born in harmonizing ideas, designing, prototyping, hypothesis
              testing, and communicating with people and companies. We honed the
              mechanisms of trust.
            </p>
            <br />
            <h4 className={styles.subtitle}>Phenomena of Trust JUSTDOLUCK:</h4>
            <ul className={styles.ul}>
              <li>Simplicity and Clarity</li>
              <li>Integrity and Fairness</li>
              <li>Absolute Transparency and Verifiability</li>
              <li>Independence and Publicity (open source)</li>
            </ul>
            <img src={modal4} alt="" className={styles.img} />
            <h5 className={styles.subtitle2}>Simplicity</h5>
            <p className={styles.text}>
              Every hour there are raffles at{" "}
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    justdoluck.com
                </a>
              , and it's easy to join. Sign up - choose a raffle - the system
              will ask you to choose heads or tails? - you just have to make a
              simple choice before the 30-second technical pause.
            </p>
            <br />
            <h5 className={styles.subtitle2}>Clarity</h5>
            <p className={styles.text}>
              The choice is always made of two equally possible and equally
              probable options - "eagle and tails"!? There is no other choice.
              It's an understandable game of "eagle-eye", one tosses a coin and
              the others guess the outcome of the coin toss.
            </p>
            <br />
            <h5 className={styles.subtitle2}>Integrity</h5>
            <p className={styles.text}>
              Always an equal probability of victory/loss in the round - 50% to
              50%. Honestly or win or lose honestly, moot situations are
              excluded. <br />
              <br />
              You can read about the odds and probabilities in{" "}
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    JUSTDOLUCK
                </a>{" "}
              in the article on this topic.
            </p>
            <br />
            <h5 className={styles.subtitle2}>Fairness</h5>
            <p className={styles.text}>
              Always 100% equal conditions for all, regardless of anything or
              anyone. It is also fair that the winner is the one who has
              guessed, step by step, the results of the coin tosses more than
              anyone else.
            </p>
            <br />
            <h5 className={styles.subtitle}>Absolute Transparency</h5>
            <p className={styles.text}>
              All Participants know and see their choices and the choices of
              others. All understand that if you guessed - you move on, not
              guessed - you're out. Manipulation and misunderstanding - are
              excluded. Absolute transparency to this process gives a public
              (open source on{" "}
                <a href="https://github.com" className={clsx(styles.link, styles["link--dotted"])}>
                    GitHub
                </a>
              ) and independent algorithm JDL defining the result of a coin
              flip. JDL algorithm website -{" "}
                <a href="https://algorithm.justdoluck.com" className={clsx(styles.link, styles["link--dotted"])}>
                    algorithm.justdoluck.com
                </a>{" "}
            </p>
            <br />
            <h5 className={styles.subtitle}>Verifiability</h5>
            <p className={styles.text}>
              Everyone can check "why heads or tails fell? By accessing the{" "}
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    JDL algorithm
                </a>{" "}
              website, any value can be double-checked, and if you want, you can
              do the calculations yourself.
              <br />
              <br /> The{" "}
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    JDL algorithm
                </a>{" "}
              has the function of validating the calculations of any previous
              combinations at any time and is in the public domain. That is,
              anyone can check why heads fell out a minute ago, and tails fell
              out five minutes ago.
            </p>
            <br />
            <h5 className={styles.subtitle}>Independence</h5>
            <p className={styles.text}>
              No one can affect the operation of the JDL algorithm and the
              mechanism for determining the Winner, except the choice of the
              Participant.
            </p>
            <br />
            <h5 className={styles.subtitle}>Publicity</h5>
            <p className={styles.text}>
              Everything is public. Everyone, everything is visible. Everything
              is recorded and can always be double-checked. The JDL algorithm is
              a public, auditable element of{" "}
                <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                    JUSTDOLUCK
                </a>{" "}
                to ensure maximum transparency and demonstrable integrity of the
                gaming processes.
                <br/>
                <br/> Understanding the power of trust has helped transform the
                JDL business model and make it an enforceable life right for
                everyone. There is tremendous potential for development in the
                trust economy - a living, evolving ecosystem within which everyone
                wins.
            </p>
              <div className={styles.line}>
                  <div className={styles.round}></div>
              </div>
              <p className={styles["text--small"]}>
                  <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                      JUST DO LUCK
                  </a>
                  &nbsp; is a simple game of "eagle and tails", but on a scale. The
                  game is not between two people, but between many people who join
                  together in play groups and jointly participate in a raffle at
                  the same time according to the same rules with equal chances and
                  opportunities.
              </p>
              <br/>
              <br/>
              <p className={styles["text--small"]}>
                  <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                      JUST DO LUCK
                  </a>
                  &nbsp; is an experiment that reveals the beauty of pure chance.
              </p>
          </>
        ) : null}

        {id === 4 ? (
          <>
            <p className={styles.date}>11/29/2022</p>
            <h3 className={styles.title}>
              How do I become a winner at JUST DO LUCK?
            </h3>
            <p className={styles.text}>
              In order to be a WINNER in JUST DO LUCK raffles, you simply have to
              be the luckiest. Players compete with each other only on the level
              of pure chance. In JUST DO LUCK - there is no need to make the
              best move and no rational choice of the best move, only a
              chaotic-intuitive game strategy.{" "}
            </p>
            <br />
            <p className={styles.text}>
              Intuitive, random choices are the only kind of dominant strategy
              that can be considered rational.
              <br />
              <br /> It's as simple as that!{" "}
              <span>The optimal strategy is to play entirely at random.</span>
              <br />
              <br /> No player is able to form a strategy for the game from the
              beginning, he can only guess it at random. This is more difficult
              than it seems, because the choice must be completely random.
            </p>
            <br />
            <p className={styles.text}>
              Game theory relies on the assumption that, regardless of the goal
              of the game and its circumstances, there will be a strategy to
              succeed. In the case of JUST DO LUCK, the design, logic, and model
              of the game were created so that all players perform actions
              (simultaneously and sequentially) in a state of 50/50 certainty.
              That is, there is no optimal strategy for the player, which
              provides him with the maximum possible average win or the minimum
              possible average loss, regardless of what strategies the
              conditional opponents use.
            </p>
            <br />
            <p className={styles.text}>
              Consequently, making recommendations for reasonable behavior of
              JUST DO LUCK game participants (and this is the goal of game
              theory - to determine the optimal strategy for the behavior of
              players) is to play completely at random.
            </p>
            <img src={modal5} alt="" className={styles.img} />
            <h5 className={styles.subtitle}>The inference is that:</h5>
            <ul className={styles.ul}>
              <li>
                The JDL demonstrates the impossibility of rational decision
                making.
              </li>
              <li>
                In JDL, the assumption that subjects are rational is wrong.
              </li>
              <li>
                In JDL, both rational and irrational decisions generate new
                rational or irrational decisions, which are essentially the
                same, and have no effect on game logic (game strategy).
              </li>
            </ul>
              <div className={styles.border}>
                  <p className={styles.border__title2}>
                      It's a controlled dynamic that happens entirely by chance!
                  </p>
              </div>
              <p className={styles.text} style={{fontStyle: "italic"}}>
                  Primal simplicity.
              </p>
              <div className={styles.line}>
                  <div className={styles.round}></div>
              </div>
              <p className={styles["text--small"]}>
                  <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                      JUST DO LUCK
                  </a>
                  &nbsp; is a simple game of "eagle and tails", but on a scale. The
                  game is not between two people, but between many people who join
                  together in play groups and jointly participate in a raffle at
                  the same time according to the same rules with equal chances and
                  opportunities.
              </p>
              <br/>
              <br/>
              <p className={styles["text--small"]}>
                  <a href="https://justdoluck.com" target="_blank" className={clsx(styles.link, styles["link--dotted"])}>
                      JUST DO LUCK
                  </a>
                  &nbsp; is an experiment that reveals the beauty of pure chance.
              </p>
          </>
        ) : null}
      </div>
    </Modal>
  );
};
