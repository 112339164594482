import React from "react";
import styles from "./style.module.scss";
import clsx from "clsx";
import {
  icon1,
  icon10,
  icon11,
  icon12,
  icon13,
  icon14,
  icon15,
  icon16,
  icon17,
  icon18,
  icon19,
  icon2,
  icon20,
  icon21,
  icon22,
  icon23,
  icon24,
  icon25,
  icon26,
  icon27,
  icon28,
  icon29,
  icon3,
  icon30,
  icon31,
  icon32,
  icon33,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  IconLogoWhite2,
} from "../../assets";
import Icon from "./Idea";

export const Clock = () => {

  return (
    <div className={styles.container}>
      <div className="container container--mob-nopad">
        <div className={styles.heading}>
        </div>

      </div>
      <div className={styles.used}>
        <div className={styles.feature}>
          <div className={styles.feature_title}>
            What is your feature?
          </div>
          <div className={styles.feature_text}>
            At the heart of "JUST DO LUCK" is a unique technology that makes all prize draws (games) simple, fair, and transparent, providing users with enjoyable and trustworthy experience.
          </div>
          <div className={styles.feature_team}>
            <a href="https://justdoluck.medium.com/what-is-the-uniqueness-of-the-gaming-platform-just-do-luck-d8c665a84600" target="_blank">
              Our team has written an article about the unique features and benefits of
              "JUST DO LUCK," the prize draw marketing platform that that will revolutionize the industry.
            </a>
          </div>
        </div>
        <div className={styles["used-pinkContainer"]}>
          <div className={styles.idea}>

            <div className={styles["idea-text"]}>
              <p
                className={clsx(styles["idea-text--one"], "wow animate ")}
                data-wow-delay="1.8s"
              >
                This is
              </p>
              <p
                className={clsx(styles["idea-text--two"], "wow animate ")}
                data-wow-delay="2s"
              >
                incredibly
              </p>
              <p
                className={clsx(styles["idea-text--three"], "wow animate ")}
                data-wow-delay="2.3s"
              >
                cool!
              </p>
            </div>
            <Icon />
          </div>
        </div>
        <div className={styles["text-container"]}>
          <div className={styles["text-with-images-mob"]}>
            JUST DO LUCK –<br /> a provider of &nbsp;
            <img src={icon31} alt="" />
            <img src={icon32} alt="" />
            <img src={icon33} alt="" />
            emotion liquidity
            <br />
            <img src={icon21} alt="" className={styles.icon} />
            <img src={icon22} alt="" className={styles.icon} />
            <img src={icon23} alt="" className={styles.icon} />
            <img src={icon24} alt="" className={styles.icon} />
            <img src={icon25} alt="" className={styles.icon} />
            <img src={icon26} alt="" className={styles.icon} />
            <img src={icon27} alt="" className={styles.icon} />
            <br />
            for effective marketing <img src={icon17} alt="" />
            <img src={icon18} alt="" />
            <img src={icon19} alt="" />
            <img src={icon20} alt="" />
          </div>
          <div className={styles["text-with-images"]}>
            <span>JUST DO LUCK – </span>
            <img src={icon1} alt="" />
            <img src={icon2} alt="" />
            <img src={icon3} alt="" />
            <img src={icon4} alt="" />
            <img src={icon5} alt="" />
            <img src={icon6} alt="" />
            <img src={icon7} alt="" />
            <img src={icon8} alt="" />
            <div className={styles["text-with-images-inner"]}>
              <div className={styles.icons}>
                <img src={icon9} alt="" />
                <img src={icon10} alt="" />
                <img src={icon11} alt="" />
                <img src={icon12} alt="" />
                <img src={icon13} alt="" />
                <img src={icon14} alt="" />
                <img src={icon15} alt="" />
                <img src={icon16} alt="" />
                <img src={icon17} alt="" />
                <img src={icon18} alt="" />
                <img src={icon19} alt="" />
                <img src={icon20} alt="" />
              </div>
              <div className={styles["text-with-images-column"]}>
                <p>a provider of emotion </p>
                <p>
                  liquidity <img src={icon21} alt="" className={styles.icon} />
                  <img src={icon22} alt="" className={styles.icon} />
                  <img src={icon23} alt="" className={styles.icon} />
                  <img src={icon24} alt="" className={styles.icon} />
                  <img src={icon25} alt="" className={styles.icon} />
                  <img src={icon26} alt="" className={styles.icon} />
                  <img src={icon27} alt="" className={styles.icon} />
                  <img src={icon28} alt="" className={styles.icon} />
                </p>
              </div>
            </div>
            <p>
              {" "}
              for effective marketing <img src={icon29} alt="" />
              <img src={icon30} alt="" />
              <img src={icon31} alt="" />
              <img src={icon32} alt="" />
              <img src={icon33} alt="" />
            </p>
          </div>
          <div className={styles.footer}>
            <p className={styles.footer__title}>
              CONSTANTLY EVOKE AN EMOTIONAL RESPONSE
            </p>
            <IconLogoWhite2 className={styles.footer__logo} />
          </div>
        </div>
      </div>
    </div>
  );
};
