import React, {useRef} from "react";
import styles from "./style.module.scss";
import clsx from "clsx";
import {White} from "../ui/white/White";
import {IconArrBottom, IconArrTop, IconAtten, IconAttention, IconText} from "../../assets";
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Autoplay, Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const Work = () => {
  const navigationPrevRef1 = useRef(null);
  const navigationNextRef1 = useRef(null);
  return (
      <div className="container container--mob-nopad">
        <White>
          <div className={styles.container}>

            <div className={styles.offer}>
              What can your clients offer to their customers with the help of your platform?
            </div>
            <div className={styles.qua}>
              <div className={styles["qua-inner"]}>
                <div className={styles["qua-item"]}>
                  <h3 className={styles["qua-item__title"]}>
                    Quantitative values
                  </h3>
                  <ol className={styles["qua-item__list"]}>
                    <li>
                      Boost revenue through increased sales.

                    </li>
                    <li>Increase brand visibility and attract more attention.
                    </li>
                    <li>Improve marketing performance and advertising results.
                    </li>
                    <li>Build customer loyalty and retention.
                    </li>
                    <li>Enhance the effectiveness of current marketing and advertising efforts.
                    </li>
                    <li>Expand the customer base to reach new audiences.</li>
                  </ol>
                </div>
                <div className={styles["qua-item"]}>
                  <h3 className={styles["qua-item__title"]}>Quality values</h3>
                  <ol className={styles["qua-item__list"]}>
                    <li>
                      Commitment to reducing social and economic inequality on a global scale.

                    </li>
                    <li>
                      Emphasize ethical value distribution practices based on principles of absolute fairness.

                    </li>
                    <li>
                      Additional opportunities for building loyalty programs.
                    </li>
                    <li>
                      Increased opportunities in the organization of an interactive dialogue with the target audience.
                    </li>
                    <li>
                      Formation of an additional emotional connection.

                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className={styles.icon}>
              <IconAtten className={styles.icon__atten}/>
              <IconAttention className={styles.icon__round}/>
            </div>
            <a href="https://justdoluck.com" className={styles.btn} target={"_blank"}>
              Go to the prize draw platform
            </a>
            <div className={styles.svgtext}>
              <IconText/>
            </div>
            <h2 className={clsx(styles.title, styles.title__colored)}>How does JUST DO LUCK work?</h2>
            <Swiper
                slidesPerView="auto"
                freeMode={true}
                pagination={true}
                spaceBetween={10}
                className={clsx(
                    styles.slider,
                    "workSlider threeSlider threeSlider--pag"
                )}
                onBeforeInit={(swiper: any) => {
                  swiper.params.navigation.prevEl = navigationPrevRef1.current;
                  swiper.params.navigation.nextEl = navigationNextRef1.current;
                }}
                navigation={{
                  prevEl: navigationPrevRef1.current,
                  nextEl: navigationNextRef1.current,
                }}
                modules={[Navigation, Autoplay, A11y, Pagination]}
            >
              <SwiperSlide>
                <div className={styles["slide-inner"]}>
                  <div className={clsx(styles.block, styles["block--one"])}>
                    <div className={styles.block__count}>1</div>
                    <p className={styles.block__text}>
                      Development of strategy and tactics for prize draws
                    </p>
                    <span className={styles["block-arrow--bottom"]}>
                    <IconArrBottom />
                  </span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles["slide-inner"]}>
                <div className={clsx(styles.block, styles["block--two"])}>
                  <span className={styles["block-arrow--top"]}>
                    <IconArrTop />
                  </span>
                  <p className={styles.block__text}>
                    Creating a prize draw

                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles["slide-inner"]}>
                <div className={clsx(styles.block, styles["block--three"])}>
                  <p className={styles.block__text}>
                    Customizing the advertising look of the game page
                  </p>
                  <span className={styles["block-arrow--bottom"]}>
                    <IconArrBottom />
                  </span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles["slide-inner"]}>
                <div className={clsx(styles.block, styles["block--four"])}>
                  <p className={styles.block__text}>
                    Acceptance
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <div className={styles.nav}>

              <div className={styles["nav-count"]}></div>

            </div>
          </Swiper>
          <Swiper
            slidesPerView="auto"
            freeMode={true}
            pagination={true}
            spaceBetween={10}
            className={clsx(
              styles.slider,
              "workSlider threeSlider threeSlider--pag"
            )}
            onBeforeInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef1.current;
              swiper.params.navigation.nextEl = navigationNextRef1.current;
            }}
            navigation={{
              prevEl: navigationPrevRef1.current,
              nextEl: navigationNextRef1.current,
            }}
            modules={[Navigation, Autoplay, A11y, Pagination]}
          >
            <SwiperSlide>
              <div className={styles["slide-inner"]}>
                <div
                  className={clsx(
                    styles.block,
                    styles["block--one"],
                    styles["block--pink"]
                  )}
                >
                  <div
                    className={clsx(
                      styles.block__count,
                      styles["block__count--pink"]
                    )}
                  >
                    2
                  </div>
                  <p className={styles.block__text}>
                    Get QR codes and short links in your personal account
                  </p>
                  <span
                    className={clsx(
                      styles["block-arrow--bottom"],
                      styles["block-arrow--pink"]
                    )}
                  >
                    <IconArrBottom />
                  </span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles["slide-inner"]}>
                <div
                  className={clsx(
                    styles.block,
                    styles["block--two"],
                    styles["block--pink"]
                  )}
                >
                  <span
                    className={clsx(
                      styles["block-arrow--top"],
                      styles["block-arrow--pink"]
                    )}
                  >
                    <IconArrTop />
                  </span>
                  <p className={styles.block__text}>
                    Invite the participants to the game by connecting the social
                    network and other communication channels
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles["slide-inner"]}>
                <div
                  className={clsx(
                    styles.block,
                    styles["block--three"],
                    styles["block-arrow--grad"]
                  )}
                >
                  <p className={styles.block__text}>
                    Running the game - determining the winner
                  </p>
                  <span
                    className={clsx(
                      styles["block-arrow--bottom"],
                      styles["block-arrow--pink"]
                    )}
                  >
                    <IconArrBottom />
                  </span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles["slide-inner"]}>
                <div
                  className={clsx(
                    styles.block,
                    styles["block--four"],
                      styles["block--pink"]
                  )}
                >
                  <p className={styles.block__text}>
                    Get analytics on the raffle and award the prize to the Winner
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <div className={styles.nav}>
              <div className={styles["nav-count"]}></div>

            </div>
          </Swiper>


        </div>
      </White>
    </div>
  );
};
