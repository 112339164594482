import React, {FC} from "react";
import Modal from "../modal";
import styles from "./style.module.scss";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CalcExample: FC<Props> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} wide>
      <div className={styles.modal}>
        <h3 className={styles.modal__title}>
          An example of calculating an additional payment for exceeding the
          limit for players in one raffle
        </h3>
        <ul>
          <li>
            You  have successfully completed the raffle
            id 2 138
          </li>
          <li>
            Maximum number of participants within one raffle in package – 500
          </li>
          <li>Participated in the raffle prize  - 2 200 participants</li>
          <li>Limit exceeded: 2 200 – 500 = 1 700 participants</li>
          <li>Additional payment: 1 700 * $0,01/person = $17</li>
        </ul>
      </div>
    </Modal>
  );
};
