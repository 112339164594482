import {useState} from 'react';
import style from './calc.module.scss';

let Calc = props => {
    const pricesOfPackets = [333, 899, 1699];
    const limitsOfPackets = [5000, 15000, 30000];
    const overheatPrice = [0, 0.02, 0.02];
    const gamesLimits = [60, 240, 600];
    const lvlA = [
        {
            packet: 7,
            limit: 3
        },
        {
            packet: 6,
            limit: 3
        },
        {
            packet: 5,
            limit: 3
        },
        {
            packet: 4,
            limit: 3
        },
        {
            packet: 3,
            limit: 3
        },
        {
            packet: 2,
            limit: 3
        },
        {
            packet: 1,
            limit: 3
        }
    ];

    const lvlB = [
        {
            packet: 8,
            limit: 4
        },
        {
            packet: 7,
            limit: 4
        },
        {
            packet: 6,
            limit: 4
        },
        {
            packet: 5,
            limit: 4
        },
        {
            packet: 4,
            limit: 4
        },
        {
            packet: 3,
            limit: 4
        },
        {
            packet: 2,
            limit: 4
        },
        {
            packet: 1,
            limit: 4
        }
    ];

    const lvlC = [
        {
            packet: 9,
            limit: 5
        },
        {
            packet: 8,
            limit: 5
        },
        {
            packet: 7,
            limit: 5
        },
        {
            packet: 6,
            limit: 5
        },
        {
            packet: 5,
            limit: 5
        },
        {
            packet: 4,
            limit: 5
        },
        {
            packet: 3,
            limit: 5
        },
        {
            packet: 2,
            limit: 5
        },
        {
            packet: 1,
            limit: 5
        }
    ];
    const predictedOverlimit = [
        {
            amount: 1000,
            percent: 0.18
        },
        {
            amount: 3000,
            percent: 0.16
        },
        {
            amount: 5000,
            percent: 0.14
        },
        {
            amount: 7000,
            percent: 0.12
        },
        {
            amount: 10000,
            percent: 0.1
        },
        {
            amount: 15000,
            percent: 0.1
        },
        {
            amount: 20000,
            percent: 0.08
        },
        {
            amount: 25000,
            percent: 0.06
        },
        {
            amount: 30000,
            percent: 0.04
        },
        {
            amount: 35000,
            percent: 0.02
        }
    ];
    const getOverprice = () => {
        return {
            min:
                ((((gamesLimits[0] * fullfiledGamesPercent) / 100) * overliomitPercent) / 100) *
                getMathExcpectation() *
                overheatPrice[0],
            mid:
                ((((gamesLimits[1] * fullfiledGamesPercent) / 100) * overliomitPercent) / 100) *
                getMathExcpectation() *
                overheatPrice[1],
            max:
                ((((gamesLimits[2] * fullfiledGamesPercent) / 100) * overliomitPercent) / 100) *
                getMathExcpectation() *
                overheatPrice[2]
        };
    };
    const getMathExcpectation = () => {
        let sum = 0;
        predictedOverlimit.forEach(value => {
            sum += value.amount * value.percent;
        });
        return sum;
    };

    let level = props.level;
    const [minSell, setMinSell] = useState(0);
    const [midSell, setMidSell] = useState(0);
    const [maxSell, setMaxSell] = useState(0);
    const [fullfiledGamesPercent, setffgp] = useState(10);
    const [overliomitPercent, setOverLimitPercent] = useState(10);

    const getClass = () => {
        if (level === 'A') return lvlA;
        if (level === 'B') return lvlB;
        if (level === 'C') return lvlC;
    };

    let calfFinal = () => {
        let sum = 0;
        getClass().map(value => {
            let commonPrice =
                (minSell * pricesOfPackets[0] + midSell * pricesOfPackets[1] + maxSell * pricesOfPackets[2]) / 100;
            let overPrice = getOverprice();
            let finalOverPrice = overPrice.min * minSell + overPrice.mid * midSell + overPrice.max * maxSell;
            let direct = value.packet * commonPrice;
            let extra = (value.limit / 100) * finalOverPrice;
            let common = direct + extra;
            sum += common;
        });
        props.setGlobalSum(sum);
        return sum;
    };
    return (
        <div className={style.calc}>
            <div>
                <div>
                    <div>
                        <div className={style.title}>Enter estimated amount of sold min packages</div>
                        <div>
                            <input
                                type={'number'}
                                onChange={e => {
                                    setMinSell(+e.target.value);
                                }}
                                value={minSell}
                            />
                        </div>
                    </div>

                    <div>Enter estimated amount of sold mid packages</div>
                    <div>
                        <input
                            type={'number'}
                            onChange={e => {
                                setMidSell(+e.target.value);
                            }}
                            value={midSell}
                        />
                    </div>

                    <div>Enter estimated amount of sold max packages</div>
                    <div>
                        <input
                            type={'number'}
                            onChange={e => {
                                setMaxSell(+e.target.value);
                            }}
                            value={maxSell}
                        />
                    </div>
                </div>

                <div>
                    <div>Enter estimated percent of fulfuilled games</div>
                    <div>
                        <input
                            type={'number'}
                            onChange={e => {
                                setffgp(+e.target.value);
                            }}
                            value={fullfiledGamesPercent}
                        />
                    </div>
                </div>

                <div>
                    <div>Enter estimated percent of overlimited games</div>
                    <div>
                        <input
                            type={'number'}
                            onChange={e => {
                                setOverLimitPercent(+e.target.value);
                            }}
                            value={overliomitPercent}
                        />
                    </div>
                </div>
            </div>
            <table>
                <td>Month </td>
                <td>Packets</td>
                <td>Extra, $</td>
                <td>Sum, $</td>
                {getClass().map((value, index) => {
                    let commonPrice =
                        (minSell * pricesOfPackets[0] + midSell * pricesOfPackets[1] + maxSell * pricesOfPackets[2]) /
                        100;
                    let overPrice = getOverprice();
                    let finalOverPrice = overPrice.min * minSell + overPrice.mid * midSell + overPrice.max * maxSell;
                    let direct = value.packet * commonPrice;
                    let extra = (value.limit / 100) * finalOverPrice;
                    let common = direct + extra;
                    return (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{Math.round(direct)}</td>
                            <td>{Math.round(extra)}</td>
                            <td>{Math.round(common)}</td>
                        </tr>
                    );
                })}
            </table>
            <br/>
            Common {Math.round(calfFinal())}$
        </div>
    );
};

const CalcSet = () => {
    const [periods, setPeriods] = useState([0]);
    const [level, setLevel] = useState('A');
    const [commonSum, setCommonSum] = useState({});

    let setGlobalSum = (sum, index) => {
        let _sum = JSON.parse(JSON.stringify(commonSum));
        _sum['at' + index] = sum;
        if (JSON.stringify(commonSum) !== JSON.stringify(_sum)) setCommonSum(_sum);
    };

    let getTotal = () => {
        let sum = 0;
        Object.values(commonSum).forEach(v => {
            sum += +v;
        });
        return Math.round(sum);
    };
    return (
        <div className={style.calc}>
            <div className={style.calc_body}>
                <div className={'all_calcs'}>
                    <div className={style.title}>Agent estimate profit</div>
                    <div>
                        <div>Input calculation period</div>
                        <div>
                            <input
                                type={'number'}
                                min={1}
                                max={24}
                                onChange={e => {
                                    let _periods = [];
                                    for (let i = 0; i < +e.target.value; i++) {
                                        _periods.push(null);
                                    }
                                    setPeriods(_periods);
                                }}
                                defaultValue={1}
                            />
                        </div>
                    </div>

                    <div>
                        Select your level (the percentage of payments to the agent depends on the level, see{' '}
                        <a>the payment table</a>)
                    </div>
                    <div>
                        <input
                            type={'radio'}
                            onClick={() => {
                                setLevel('A');
                            }}
                            checked={level === 'A'}
                        />{' '}
                        A (Attracted up to 10 Clients )
                    </div>
                    <div>
                        <input
                            type={'radio'}
                            onClick={() => {
                                setLevel('B');
                            }}
                            checked={level === 'B'}
                        />{' '}
                        B (Attracted from 10 to 30 Clients )
                    </div>
                    <div>
                        <input
                            type={'radio'}
                            onClick={() => {
                                setLevel('C');
                            }}
                            checked={level === 'C'}
                        />{' '}
                        C (Attracted from 30 Clients )
                    </div>
                    <br />
                    <p>Note: Average amount of fulfilled games is about 10%</p>
                    <p>Note: Average amount of overlimited games is about 10%</p>
                    <p>
                        Provided that all Clients will use the service during the entire period, the payment to the Agent
                        will be:
                    </p>
                    <br/>
                    <br/>
                    <div>
                        {periods.map((value, index) => {
                            return (
                                <div>
                                    <br/>
                                    <br/>
                                    <h3>Clients attracted at {index + 1} month</h3>
                                    <Calc
                                        level={level}
                                        setGlobalSum={e => {
                                            setGlobalSum(e, index);
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <br/>
                    <hr/>
                    <br/>
                    <div style={{ color: 'red' }}>
                        <h4>Total sum: {getTotal()}$</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CalcSet;
