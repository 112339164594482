import caseImage1 from "../components/cases/images/case1.bmp"
import caseImage2 from "../components/cases/images/case2.bmp"
import caseImage3 from "../components/cases/images/case3.bmp"
import caseImage4 from "../components/cases/images/case4.bmp"
import caseImage5 from "../components/cases/images/case5.bmp"

import article1Image from "../components/articles/images/article1.jpg"
import article2Image from "../components/articles/images/article2.jpg"
import article3Image from "../components/articles/images/article3.jpg"

export type ArticleType = {
  id: number;
  title: string;
  desc: string;
  image: string;
  modal?: string;
  link: string
};

export type CaseType = {
  id: number;
  title: string;
  desc: string;
  image: string;
  modal?: string;
};


export type NewsType = {
  createdAt: "2022-10-20T05:54:56.000Z";
  id: number;
  photo: string;
  shortDescription: string;
  title: string;
  viewsCount: number;
  description?: string;
};

export const articles = [
  {
    id: 1,
    title: `What is the uniqueness of the prize draw platform “JUST DO LUCK”?`,
    desc: "The prize draw platform “JUST DO LUCK” meets all the principles of uniqueness: novelty, originality, individuality, independence, and interest.",
    image: article1Image,
    modal: ``,
    link: "https://justdoluck.medium.com/what-is-the-uniqueness-of-the-gaming-platform-just-do-luck-d8c665a84600"
  },
  {
    id: 2,
    title: `About the startup prize draw platform “JUST DO LUCK”`,
    desc: "JUST DO LUCK (JDL) is an innovative online platform that employs advanced technology to select winners of prize drawings. Its unique features and benefits are detailed in the article.",
    image: article2Image,
    modal: ``,
    link: "https://justdoluck.medium.com/about-the-startup-prize-draw-platform-just-do-luck-8565df212b7c"
  },
  {
    id: 3,
    title: `Short instruction for using the JUST DO LUCK prize draw platform.`,
    desc: "The general scheme for using the “JDL” prize draw platform",
    image: article3Image,
    modal: ``,
    link: "https://justdoluck.medium.com/short-instruction-for-using-the-just-do-luck-prize-draw-platform-b8042147b8c8"
  },
  //
  // {
  //   id: 4,
  //   title: "Maximum transparency and impeccable honesty JUST DO LUCK",
  //   desc: "Transparency, honesty, fairness define the phenomenon of public trust in any online service. Trust is a new form of social capital",
  //   image: image4,
  //   modal:'',
  // },
  //
  // {
  //   id: 5,
  //   title: "How do I become a winner at JUST DO LUCK?",
  //   desc: " In order to be a WINNER in JUST DO LUCK raffles, you simply have to be the luckiest. Players compete with each other only on the level of pure chance.",
  //   image: image5,
  //   modal:'',
  // },
];



export const cases = [
  {
    id: 1,
    title: "Case №1",
    desc: " The ability to generate the future is key to success. The future can be talked about, traded about, transformed",
    image: caseImage1,
    modal: ``,
  },
  {
    id: 2,
    title: "Case №2",
    desc: " The ability to generate the future is key to success. The future can be talked about, traded about, transformed",
    image: caseImage2,
    modal:'',
  },
  {
    id: 3,
    title: "Case №3",
    desc: "The ability to generate the future is key to success. The future can be talked about, traded about, transformed",
    image: caseImage3,
    modal:'',
  },

  {
    id: 4,
    title: "Case №4",
    desc: "The ability to generate the future is key to success. The future can be talked about, traded about, transformed",
    image: caseImage4,
    modal:'',
  },

  {
    id: 5,
    title: "Case №5",
    desc: "The ability to generate the future is key to success. The future can be talked about, traded about, transformed",
    image: caseImage5,
    modal:'',
  },
];


