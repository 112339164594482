import {useState} from 'react';
import style from './calc2.module.scss';

let Calc2 = props => {
    let players = [];
    for (let i = 0; i < 100; i++) players.push(null);
    /*input variables*/
    const [averagePercentage, setAveragePercentage] = useState(0);
    const [prisePrice, setPrisePrice] = useState(0);
    const [visitorsAmount, setVisitorsAmount] = useState(0);
    const [bannerAmount, setBannerAmount] = useState(0);
    const [linkAmount, setLinkAmount] = useState(0);
    const [conversion1, setConversion1] = useState(0);
    const [conversion2, setConversion2] = useState(0);
    const [conversion3, setConversion3] = useState(0);
    const [CPMPercent, setCPMPercent] = useState(0);
    const [CPCPercent, setCPCPercent] = useState(0);
    const [CPAPercent, setCPAPercent] = useState(0);
    const [showBlock, setShowBlock] = useState(0);

    const getAverGamePrice = () =>
        packetConstants[packet].cost / ((packetConstants[packet].maxPerMonth * averagePercentage) / 100);
    const getShowedBannerAmount = () => (bannerAmount * visitorsAmount * conversion1 * 2) / 100;
    const getColdLead1 = () => (conversion2 * bannerAmount * visitorsAmount * conversion1 * 2) / 10000;
    const getWarmLead1 = () => (conversion3 * linkAmount * visitorsAmount * conversion1 * 2) / 10000;
    const getCPC = () => (((getAverGamePrice() + prisePrice) / getColdLead1()) * CPCPercent) / 100;
    const getCPA = () => (((getAverGamePrice() + prisePrice) / getWarmLead1()) * CPAPercent) / 100;
    const getCPL = () => (getAverGamePrice() + prisePrice) / (getWarmLead1() + getColdLead1());
    const getCPB1000 = () => (((getAverGamePrice() + prisePrice) / getShowedBannerAmount()) * 1000 * CPMPercent) / 100;

    const round2 = v => Math.round(v * 100) / 100;
    const setMinShowLvl = v => {
        v > showBlock && setShowBlock(v);
    };

    const packetConstants = {
        min: {
            cost: 333,
            maxPerMonth: 60
        },
        mid: {
            cost: 899,
            maxPerMonth: 240
        },
        max: {
            cost: 1699,
            maxPerMonth: 600
        }
    };
    const [packet, setPacket] = useState('min');

    return (
        <div className={style.calc}>
            <div className={style.calc_body}>
                {showBlock >= 0 && (
                    <div className={style.showBlock}>
                        <div className={style.title}>Select your packege</div>
                        <div className={style.packet_selector}>
                            <div>
                                <input
                                    type={'radio'}
                                    onClick={() => {
                                        setPacket('min');
                                    }}
                                    checked={packet === 'min'}
                                />{' '}
                                Min
                            </div>
                            <div>
                                <input
                                    type={'radio'}
                                    onClick={() => {
                                        setPacket('mid');
                                    }}
                                    checked={packet === 'mid'}
                                />{' '}
                                Mid
                            </div>
                            <div>
                                <input
                                    type={'radio'}
                                    onClick={() => {
                                        setPacket('max');
                                    }}
                                    checked={packet === 'max'}
                                />{' '}
                                Max
                            </div>
                        </div>

                        <table>
                            <tr>
                                <td>Cost per month, $</td>
                                <td>{packetConstants[packet].cost}</td>
                            </tr>
                            <tr>
                                <td>Cost per day, $</td>
                                <td>{round2(packetConstants[packet].cost / 30)}</td>
                            </tr>
                            <tr>
                                <td>Max number of games, month</td>
                                <td>{packetConstants[packet].maxPerMonth}</td>
                            </tr>

                            <tr>
                                <td>Max number of games, day</td>
                                <td>{packetConstants[packet].maxPerMonth / 30}</td>
                            </tr>
                        </table>
                        <br />
                        <div>Enter Average percentage of opportunities realized, %</div>
                        <div>
                            <input
                                type={'number'}
                                onChange={e => {
                                    setAveragePercentage(+e.target.value);
                                    setMinShowLvl(1);
                                }}
                                value={averagePercentage}
                            />
                        </div>
                    </div>
                )}
                {showBlock >= 1 && (
                    <div className={style.showBlock}>
                        <table>
                            <tr>
                                <td>Average percentage of realisation, %</td>
                                <td>{averagePercentage}</td>
                            </tr>

                            <tr>
                                <td>Average number of games, month</td>
                                <td>{(packetConstants[packet].maxPerMonth * averagePercentage) / 100}</td>
                            </tr>

                            <tr>
                                <td>Average number of games, day</td>
                                <td>{((packetConstants[packet].maxPerMonth / 30) * averagePercentage) / 100}</td>
                            </tr>
                            <tr>
                                <td>Average cost per game, $</td>
                                <td>{round2(getAverGamePrice())}</td>
                            </tr>
                        </table>
                        <br />
                        <div>Enter Prize value, $</div>
                        <div>
                            <input
                                type={'number'}
                                onChange={e => {
                                    setPrisePrice(+e.target.value);
                                    setMinShowLvl(2);
                                }}
                                value={prisePrice}
                            />
                        </div>

                        <div>
                            <div>Enter visitors amount</div>
                            <div>
                                <input
                                    type={'number'}
                                    onChange={e => {
                                        setVisitorsAmount(+e.target.value);
                                        setMinShowLvl(2);
                                    }}
                                    value={visitorsAmount}
                                />
                            </div>
                        </div>

                        <div>
                            <div>Enter conversion1, %</div>
                            <div>
                                <input
                                    type={'number'}
                                    onChange={e => {
                                        setConversion1(+e.target.value);
                                        setMinShowLvl(2);
                                    }}
                                    value={conversion1}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {showBlock >= 2 && (
                    <div className={style.showBlock}>
                        <table>
                            {players
                                .map((_, index) => {
                                    const count = Math.round((visitorsAmount * conversion1) / Math.pow(2, index) / 100);
                                    if (!count) return null;
                                    return (
                                        <tr>
                                            <td>Players on step {index + 1}</td>
                                            <td>{count}</td>
                                        </tr>
                                    );
                                })
                                .filter(v => v)}
                            <tr>
                                <td>Total game players as standalone subjects</td>
                                <td>{(visitorsAmount * conversion1 * 2) / 100}</td>
                            </tr>
                        </table>
                        <br />
                        <div>
                            <div>Enter banner amount</div>
                            <div>
                                <input
                                    type={'number'}
                                    onChange={e => {
                                        setBannerAmount(+e.target.value);
                                        setMinShowLvl(3);
                                    }}
                                    value={bannerAmount}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {showBlock >= 3 && (
                    <div className={style.showBlock}>
                        <table>
                            <tr>
                                <td>Showed banner amount</td>
                                <td>{getShowedBannerAmount()}</td>
                            </tr>

                            <tr>
                                <td>Showed to unique users banner amount</td>
                                <td>{(bannerAmount * visitorsAmount * conversion1) / 100}</td>
                            </tr>
                        </table>
                        <br />

                        <div>
                            <div>Enter link amount</div>
                            <div>
                                <input
                                    type={'number'}
                                    onChange={e => {
                                        setLinkAmount(+e.target.value);
                                        setMinShowLvl(4);
                                    }}
                                    value={linkAmount}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {showBlock >= 4 && (
                    <div className={style.showBlock}>
                        <table>
                            <tr>
                                <td>Showed link amount 1</td>
                                <td>{((visitorsAmount * conversion1 * 2) / 100) * linkAmount}</td>
                            </tr>

                            <tr>
                                <td>Showed link amount 2</td>
                                <td>{((visitorsAmount * conversion1) / 100) * linkAmount}</td>
                            </tr>
                        </table>
                        <br />
                        <div>
                            <div>Enter conversion2, %</div>
                            <div>
                                <input
                                    type={'number'}
                                    onChange={e => {
                                        setConversion2(+e.target.value);
                                        setShowBlock(5);
                                    }}
                                    value={conversion2}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {showBlock >= 5 && (
                    <div className={style.showBlock}>
                        <table>
                            <tr>
                                <td>Cold lead 1</td>
                                <td>{getColdLead1()}</td>
                            </tr>
                            <tr>
                                <td>Cold lead 2</td>
                                <td>{(conversion2 * bannerAmount * visitorsAmount * conversion1) / 10000}</td>
                            </tr>
                        </table>
                        <br />
                        <div>
                            <div>Enter conversion3, %</div>
                            <div>
                                <input
                                    type={'number'}
                                    onChange={e => {
                                        setConversion3(+e.target.value);
                                        setMinShowLvl(6);
                                    }}
                                    value={conversion3}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {showBlock >= 6 && (
                    <div className={style.showBlock}>
                        <table>
                            <tr>
                                <td>Worm lead 1</td>
                                <td>{getWarmLead1()}</td>
                            </tr>
                            <tr>
                                <td>Worm lead 2</td>
                                <td>{(conversion3 * linkAmount * visitorsAmount * conversion1) / 10000}</td>
                            </tr>
                        </table>
                        <br />
                        <div>
                            <div>Enter CPM percent, %</div>
                            <div>
                                <input
                                    type={'number'}
                                    onChange={e => {
                                        setCPMPercent(+e.target.value);
                                        setMinShowLvl(7);
                                    }}
                                    value={CPMPercent}
                                />
                            </div>
                        </div>

                        <div>
                            <div>Enter CPA percent, %</div>
                            <div>
                                <input
                                    type={'number'}
                                    onChange={e => {
                                        setCPAPercent(+e.target.value);
                                        setMinShowLvl(7);
                                    }}
                                    value={CPAPercent}
                                />
                            </div>
                        </div>

                        <div>
                            <div>Enter CPC percent, %</div>
                            <div>
                                <input
                                    type={'number'}
                                    onChange={e => {
                                        setCPCPercent(+e.target.value);
                                        setMinShowLvl(7);
                                    }}
                                    value={CPCPercent}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {showBlock >= 7 && (
                    <div className={style.showBlock}>
                        <table className={style.important}>
                            <tr>
                                <td>1000 banners price, $</td>
                                <td>{round2(getCPB1000())}</td>
                            </tr>

                            <tr>
                                <td>Cost per click, $</td>
                                <td>{round2(getCPC())}</td>
                            </tr>
                            <tr>
                                <td>Cost per action, $</td>
                                <td>{round2(getCPA())}</td>
                            </tr>
                            <tr>
                                <td>Cost per lead, $</td>
                                <td>{round2(getCPL())}</td>
                            </tr>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Calc2;
