import React, {useState} from "react";
import styles from "./style.module.scss";
import clsx from "clsx";
import {IconAttention2, IconMan, IconPeople, IconRedArrow, people,} from "../../assets";
import "swiper/css";
import {ModalComponent} from "./Modal";
import YoutubeModal from "../ytb-modal/YoutubeModal";

const content = [
  {
    id: 1,
    texts: [
      {ship: "just", text: "Simple choice: heads or tails?"},
      {
        ship: "understandable",
        text: "Choice of one of two equally possible and equally probable options",
      },
      {
        ship: "honestly",
        text: "Always equal probability - 50% to 50%",
      },
      {
        ship: "Fair",
        text: "100% equal conditions for everyone all the time",
      },
    ],
  },
  {
    id: 2,
    texts: [
      {
        ship: "Micro-attention",
        text: `Short selective concentration of consciousness (short-term neural activity) - "algorithmic" value `,
      },
      {
        ship: "Micro-choice",
        text: "Hundreds of times we make it, expressing our free will, thereby determining our lives. It's language of freedom.",
      },
      {
        ship: "Micro-time",
        text: "The world is accelerating, time is shrinking, seconds are valuable today!",
      },
      {
        ship: "Micro-action",
        text: "Does not cause fatigue, fatigue, satiety",
      },
    ],
  },
  {
    id: 3,
    texts: [
      {
        ship: "TOTALLY TRANSPARENT",
        text: "All participants know and see their choice, they realize that if they guessed, you move on, if they don't guess, you're out. Manipulation and misunderstanding - excluded.",
      },
      {
        ship: "TESTED",
        text: `Everyone can check "why did you get heads or tails? by going to the JDL algorithm site. Any value can be double-checked and you can do the calculations yourself.`,
      },
      {
        ship: "INDEPENDENT",
        text: "No one can influence the work of the JDL algorithm and the mechanism for determining the winner, except for the choice of the participant",
      },
      {
        ship: "PUBLIC",
        text: "Everything is out in the open. Everyone, everything can see.",
      },
    ],
  },
  {
    id: 4,
    texts: [
      {
        ship: "THE IMPOSSIBLE IS POSSIBLE",
        text: "Yesterday there was no JDL, but today, relying only on your right to choose, you can win any prize that will be rafflen.",
      },
      {
        ship: "THE INACCESSIBLE IS ACCESSIBLE",
        text: "Yesterday the dream was far away and inaccessible, today a few clicks of the mouse or pokes in the monitor are enough to make the desired reality.",
      },
      {
        ship: "COMPLEX - SIMPLE",
        text: "The world is constantly becoming more complex, and we simplify by making the complex simple.",
      },
      {
        ship: "EXPENSIVE – FREE",
        text: "The world is upside down. Luck and intuition determine what is free and what is unavailable.",
      },
    ],
  },
];

type Obj = {
  id: number;
  texts: { ship: string; text: string }[];
};

export const Create = () => {
  const [modal, setModal] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const link = "https://www.youtube.com/embed/gJ_BB38zMBA"

  return (
    <div className={styles.container}>
      {open && <YoutubeModal link={link} isOpen={open} setOpen={setOpen}/>}
      <div className="container">
        <ModalComponent
          open={typeof modal === "number"}
          onClose={() => setModal(null)}
          content={modal !== null ? content[modal] : null}
        />

        <div className={styles.heading}>
          <h2>
            Create a controlled dynamic of emotional attention and interest{" "}
          </h2>
          <p>Growing Playgroups of Raffle Participants - Creating Value! </p>
        </div>
        <div className={styles.content}>
          <div className={styles["content-inner"]}>
            <div className={styles.twoicons}>
              <IconMan className={styles.man} />
              <IconAttention2 className={styles.round} />
            </div>

            <IconRedArrow className={styles.arr}/>
            <img src={people} alt=""/>
            <IconRedArrow className={styles.arr}/>
            <div className={styles["people-wrap"]}>
              <IconPeople className={styles.people}/>
              <IconPeople className={clsx(styles.people, styles.mob)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
