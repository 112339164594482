import React, {useState} from "react";

import styles from "./style.module.scss";
import clsx from "clsx";
import {
  appstore,
  googleplay,
  IconCopyring,
  IconLogoWhite3,
  IconSoc1,
  IconSoc2,
  IconSoc4,
  IconSoc6,
} from "../../assets";
import {ModalForm} from "./ModalForm";
import {ModalSuccess} from "./ModalSuccess";
import {OfferModal} from "../customers/OfferModal";
import {SuccesModal} from "../customers/SuccesModal";

export const Footer = () => {
  const [success, setSuccess] = useState(false);
  const [modalForm, setModalForm] = useState(false);
  const [offerForm, setOfferForm] = useState(false);
  const [offerFormSuccess, setOfferFormSuccess] = useState(false);
  const successModal = () => {
    setSuccess(true);
  };
  const successModalForm = () => {
    setOfferFormSuccess(true);
  };

  const getFooterContent = () => {
    return <>
      <div className={styles.content}>
        <div className={styles.distribution}>
          {getSocials()}
          {getStores()}
        </div>
        <div className={styles.contacts_wrapper}>
          <div className={styles.content__text}>
            Still have questions? <br/>
            You can allwasy count on feedback
          </div>
          <div className={styles.contacts_columns}>
            {getContacts1()}
            {getContacts2()}
          </div>
        </div>
      </div>
    </>
  }


  const getButtons = () => {
    return <>
      <div>
        <button
            className={styles.btn}
            onClick={() => setModalForm(true)}
        >
          LEAVE A MESSAGE
        </button>

      </div>
    </>
  }
  const getContacts2 = () => {
    return <div className={clsx(styles["content-item"])}>
      <div>
        <p className={styles.content__label}>Commercial</p>
        <a
            href="mailto:sales@justdoluck.com"
            className={styles.content__email}
        >
          sales@justdoluck.com
        </a>
      </div>
      <div>
        <p className={styles.content__label}>
          On personal data issues{" "}
        </p>
        <a
            href="mailto:privacy@justdoluck.com"
            className={styles.content__email}
        >
          privacy@justdoluck.com
        </a>
      </div>
    </div>
  }

  const getContacts1 = () => {
    return <div className={clsx(styles["content-item"])}>
      <div>
        <p className={styles.content__label}>Support</p>
        <a
            href="mailto:support@justdoluck.com"
            className={styles.content__email}
        >
          support@justdoluck.com
        </a>
      </div>

      <div>
        <p className={styles.content__label}>Marketing</p>
        <a
            href="mailto:marketing@justdoluck.com"
            className={styles.content__email}
        >
          marketing@justdoluck.com
        </a>
      </div>
    </div>
  }
  const getStores = () => {
    return <>
      <div className={styles.store}>
        <p className={styles.store__label}>Download apps</p>
        <a href="/" className={styles.store__btn}>
          <img src={googleplay} alt=""/>
        </a>
        <a href="/" className={styles.store__btn}>
          <img src={appstore} alt="" style={{
            transform: "scale(.85) translate(0px, -7px)",
          }}/>
        </a>
      </div>
    </>
  }
  const getSocials = () => {
    return <>
      <div className={styles.social}>
        <a
            href="https://www.facebook.com/justdoluck"
            target={"_blank"}
            className={clsx(styles["social-item"], "animated wow fadeInUp")}
            data-wow-offset="50"
            data-wow-delay="0.5s"
        >
          <IconSoc1/>
        </a>
        <a
            href="https://t.me/justdoluck"
            target={'_blank'}
            className={clsx(styles["social-item"], "animated wow fadeInUp")}
            data-wow-offset="50"
            data-wow-delay="0.6s"
        >
          <IconSoc2/>
        </a>
        {/* <a
              href="/"
              className={clsx(styles["social-item"], "animated wow fadeInUp")}
              data-wow-offset="50"
              data-wow-delay="0.7s"
            >
              <IconSoc3 />
            </a> */}
        <a
            href="https://www.instagram.com/justdoluckworld/"
            target={"_blank"}
            className={clsx(styles["social-item"], "animated wow fadeInUp")}
            data-wow-offset="50"
            data-wow-delay="0.8s"
        >
          <IconSoc4/>
        </a>
        {/* <a
              href="/"
              className={clsx(styles["social-item"], "animated wow fadeInUp")}
              data-wow-offset="50"
              data-wow-delay="0.8s"
            >
              <IconSoc5 />
            </a> */}
        <a
            href="https://www.facebook.com/justdoluck"
            target={"_blank"}
            className={clsx(styles["social-item"], "animated wow fadeInUp")}
            data-wow-offset="50"
            data-wow-delay="0.8s"
        >
          <IconSoc6/>
        </a>
      </div>
    </>
  }

  return (
      <footer className={styles.footer} id="about">
        <ModalSuccess open={success} onClose={() => setSuccess(false)}/>

        <ModalForm
            successModal={successModal}
            open={modalForm}
            onClose={() => {
              setModalForm(false);
            }}
        />
        <SuccesModal
            open={offerFormSuccess}
            onClose={() => setOfferFormSuccess(false)}
        />
        <OfferModal
            openSuccess={successModalForm}
            open={offerForm}
            onClose={() => setOfferForm(false)}
        />
        <div className="container container--mob-nopad">
          <div className={styles["footer-inner"]}>
            <div className={styles["link-list"]}>
              <div className={styles["link-list__item"]}>
                <p>Prize draw platform</p>
                <a href="https://justdoluck.com" target={"_blank"}>justdoluck.com</a>
              </div>
              <div className={styles["link-list__item"]}>
                <p>Algorithm Website</p>
                <a href="https://algorithm.justdoluck.com" target={"_blank"}>algorithm.justdoluck.com</a>
              </div>
              <div className={styles["link-list__item"]}>
                <p>Participants Website</p>
                <a href="https://justdoluck.me" target={"_blank"}>justdoluck.me</a>
              </div>

              <div className={styles["link-list__item"]}>
                <p>Clients Website</p>
                <a href="https://justdoluck.club" target={"_blank"}>justdoluck.club</a>
              </div>
            </div>
            <div className={styles.logo_outline}>
              <a href={"https://justdoluck.club"}><IconLogoWhite3/></a>
            </div>
            {getFooterContent()}
            <div className={styles.copyring}>
              <IconCopyring/> {new Date().getFullYear()} - Just do luck, Inc.
            </div>
            <div className={styles.law_address}>
              3876 Quarter Mile Drive, CA 92130, USA
            </div>
          </div>
      </div>
    </footer>
  );
};
