import React, {useEffect} from 'react';
import Modal from 'react-modal';
import style from "./style.module.scss"

const YoutubeModal = (props)=>{

    useEffect(()=>{
       try{
          setTimeout(()=>{
              const portal = document.getElementsByClassName("ReactModalPortal")[0];
              portal.getElementsByClassName("ReactModal__Content ")[0].style.height = "80%"
              portal.getElementsByClassName("ReactModal__Content ")[0].style.marginTop = "5%"
              console.log(portal)

          }, 100)
       }
       catch (e){}
    })
    return <div>
        <Modal
            isOpen={props.isOpen}
        >
            <div className={style.video_iframe}>
                <iframe
                    width="100%"
                    height="100%"
                    src={props.link}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
            <button onClick={()=>{props.setOpen(false)}} className={style.closeModal}>X</button>
        </Modal>
    </div>
}
export default YoutubeModal