import styles from "../style.module.scss";
import clsx from "clsx";
import numberFormat from "../../../utils/numberFormat";
import React from "react";

interface PriceTableDesktopProps {
    setCalcExample: (calc: boolean)=>void,
    servicePackets: any[]
}
export const PriceTableDesktop = (props: PriceTableDesktopProps) => {

    const setCalcExample = props.setCalcExample
    return <>
        <div className={styles.table}>
            <div className={styles["table-row"]}>
                <div className={clsx(styles["table-col"], styles.bnone)}></div>
                <div className={clsx(styles["table-col"], styles.bnone)}>
                    <div className={clsx(styles.head)}>Free+</div>
                </div>
            </div>
            <div className={styles["table-body"]}>
                <div className={styles["table-row"]}>
                    <div className={clsx(styles["table-col"], styles.bbnone)}>
                        <div className={clsx(styles.text, styles.flex)}>
                            <p>
                                Accounting period
                            </p>
                            <span
                                className={styles.help}
                                data-for="tbl"
                                data-tip="The number of raffles that can be held during the month"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                    </div>
                    <div className={styles["table-col"]}>
                        <div className={clsx(styles.text, styles.center)}>
                            <span>30 days</span>
                        </div>
                    </div>


                </div>
                <div className={styles["table-row"]}>
                    <div className={clsx(styles["table-col"], styles.bbnone)}>
                        <div className={clsx(styles.text, styles.flex)}>
                            <p>
                                Free limit on the number of participants in one game
                            </p>
                            <span
                                className={styles.help}
                                data-for="tbl"
                                data-tip="Maximum number of participants within one raffle"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                    </div>

                    <div className={styles["table-col"]}>
                        <div className={clsx(styles.text, styles.center)}>
                            <span>&#60;{numberFormat(500)}</span>
                        </div>
                    </div>
                </div>
                <div className={styles["table-row"]}>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <div className={clsx(styles.text, styles.flex)}>
                            <p>
                                Cost of service package <span>($/1 month)</span>
                            </p>

                        </div>
                        <div className={styles.switchContainer}>

                        </div>
                    </div>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <p className={clsx(styles.price, styles.center)}>$ 0</p>
                        <a
                            href="https://justdoluck.com/user/login" target="_blank"
                            className={clsx(styles.btn, styles["btn--outline"])}
                        >
                            Get started!
                        </a>
                    </div>


                </div>
                <div className={clsx(styles["table-row"])}>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <div className={clsx(styles.text, styles.flex)}>
                            <p>Integration ito streaming technology </p>
                            <span
                                className={clsx(styles.help)}
                                data-for="tbl"
                                data-tip="Ability to connect Youtube-stream while raffle is going"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                    </div>

                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <p className={clsx(styles.text, styles.center)}>
                            yes
                        </p>
                    </div>

                </div>
                <div className={clsx(styles["table-row"], styles.bg)}>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <div className={clsx(styles.text, styles.flex)}>
                            <p>Public API JDL interface</p>
                            <span
                                className={clsx(styles.help)}
                                data-for="tbl"
                                data-tip="Integration with the API of our service to develop even more attractive marketing tools"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                    </div>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <p className={clsx(styles.text, styles.center)}>yes</p>
                    </div>

                </div>
                <div className={clsx(styles["table-row"])}>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <div className={clsx(styles.text, styles.flex)}>
                            <p>Creating your own Groups</p>
                            <span
                                className={clsx(styles.help)}
                                data-for="tbl"
                                data-tip="Create your own communities within our platform. Another opportunity to hold the attention of your audience."
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                    </div>

                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <p className={clsx(styles.text, styles.center)}>yes</p>
                    </div>

                </div>
                <div className={clsx(styles["table-row"], styles.bg)}>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <div className={clsx(styles.text, styles.flex)}>
                            <p>Exceeding the limit on players in one game</p>
                            <span
                                className={clsx(styles.help)}
                                data-for="tbl"
                                data-tip="If you have reached the limit of the number of participants in one raffle, then we open the opportunity for you to exceed this limit"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                    </div>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <p className={clsx(styles.text, styles.center)}>yes</p>
                    </div>

                </div>
                <div className={clsx(styles["table-row"])}>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <div
                            className={clsx(styles.text, styles.flex)}
                            style={{lineHeight: "21px"}}
                        >
                            <p>
                                Cost of exceeding the limit on players in one game
                                (%/person){" "}
                                <span
                                    className={styles.lnk}
                                    onClick={() => setCalcExample(true)}
                                >
                        calculation example
                      </span>
                            </p>
                            <span
                                className={clsx(styles.help)}
                                data-for="tbl"
                                data-tip="The cost of each additional participant over the limit"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                    </div>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <p className={clsx(styles.text, styles.center)}>$0.01</p>
                    </div>

                </div>
                <div className={clsx(styles["table-row"], styles.bg)}>
                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <div className={clsx(styles.text, styles.flex)}>
                            <p>Payment for exceeding the limit on players</p>
                            <span
                                className={clsx(styles.help)}
                                data-for="tbl"
                                data-tip="Payment time for exceeding the number of participants in the raffles"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                    </div>

                    <div className={clsx(styles["table-col"], styles.pad)}>
                        <p className={clsx(styles.text, styles.center)}>At the end of the period</p>
                    </div>

                </div>
                <div className={clsx(styles['table-row'])}>
                    <div className={clsx(styles['table-col'], styles.center_block, styles.pad)}>
                        <div className={clsx(styles.text, styles.flex)}>
                            <p>Ability to create raffles at custom time</p>
                            <span
                                className={clsx(styles.help)}
                                data-for="tbl"
                                data-tip="You can select not only hour of raffle start time but also the minute. By default selected minute is 00"
                                data-iscapture="true"
                            >
                                        ?
                                    </span>
                        </div>
                    </div>
                    <div className={clsx(styles['table-col'], styles.pad)}>
                        <p className={clsx(styles.text, styles.center)}>yes</p>
                    </div>

                </div>
            </div>
        </div>

    </>
}