import React, {useRef, useState} from "react";
import styles from "./style.module.scss";
import {White} from "../ui/white/White";
import {IconPiramid, IconPiramidPC, weHelp,} from "../../assets";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {OfferModal} from "./OfferModal";
import {SuccesModal} from "./SuccesModal";

export const Customers = () => {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigationPrevRef1 = useRef(null);
  const navigationNextRef1 = useRef(null);

  const openSuccess = () => {
    setSuccess(true);
  };

  return (
    <div className="container container--mob-nopad">
      <OfferModal
        open={open}
        onClose={() => setOpen(false)}
        openSuccess={openSuccess}
      />
      <SuccesModal open={success} onClose={() => setSuccess(false)} />
      <White>
        <div className={styles.container}>

          <div className={styles.offer}>

          </div>
          <div className={styles.heading2}>
            <h2 className={styles.title}>
              The dynamics of growth and volume of the game group participating
              in raffles determines the degree of value of mutually{" "}
              <span> beneficial cooperation</span>
            </h2>
          </div>

          <IconPiramidPC className={styles.piramid} />
          <IconPiramid className={styles["piramid--mob"]} />
          <img src={weHelp} alt="" className={styles.weHelp} />


          <a href="https://justdoluck.com" className={styles.btn} target={"_blank"}>
            Go to the prize draw platform
          </a>
        </div>
      </White>
    </div>
  );
};
