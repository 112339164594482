import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="402"
      height="419"
      fill="none"
      viewBox="0 0 402 419"
    >
      <ellipse
        cx="204.5"
        cy="376.5"
        fill="#DE5AE4"
        rx="196.5"
        ry="42.5"
      ></ellipse>
      <path
        fill="#102048"
        d="M51.46 267.311s-7.86-19.998 24.587-47.067l-.475 44.746-24.111 2.321z"
      ></path>
      <path
        fill="#F9B384"
        d="M50.406 174.286c-11.554-23.534-20.945-40.472-26.907-37.834-1.583.686-2.322 2.744-1.847 4.538 3.113 12.295 15.828 35.142 15.828 35.142l12.926-1.846z"
      ></path>
      <path
        fill="#F9B384"
        d="M65.125 207.951l-20.312 13.138c-8.917-4.01-16.672-12.136-22.054-27.174-.738-2.111-.58-4.485.528-6.385l4.22-7.282c1.53-2.585 2.216-5.593 2.058-8.6l-.422-8.76a2.185 2.185 0 01.633-1.688l3.113-3.219c.58-.58 1.425-.633 2.058-.105l6.542 5.382-1.266-.106s1.055 11.345 9.496 11.925l4.38 5.594 11.026 27.28z"
      ></path>
      <path
        fill="#F9B384"
        d="M67.869 206.209l-2.744 1.742-10.868-27.333-4.38-5.593c-8.44-.581-9.496-11.925-9.496-11.925l1.266.105 9.919.581c2.743.158 5.329 1.371 7.334 3.429l6.753 6.965a11.384 11.384 0 012.796 4.908c2.69 9.075 2.163 18.098-.58 27.121z"
      ></path>
      <path
        fill="#F9B384"
        d="M80.004 210.43l-4.59 43.427s-13.137 10.395-13.77 11.45c-.317.475-4.801-10.184-9.76-22.32-6.385-15.566-13.507-33.612-13.507-33.612l28.49-16.146 10.025 13.033 3.112 4.168z"
      ></path>
      <path
        fill="#E28B6D"
        d="M80.004 210.43l-4.59 43.427s-13.138 10.395-13.77 11.45c-.317.475-4.802-10.184-9.761-22.32l25.008-36.673 3.113 4.116zM54.68 182.095c-.634-.739-3.536-4.591-4.433-5.805-10.024-.738-10.815-13.033-10.815-13.138l-.053-1.003h1.002c16.25-.369 19.68 3.958 21.737 6.596.37.475.686.844.95 1.108l-1.319 1.477c-.422-.369-.791-.844-1.213-1.371-1.9-2.428-4.749-6.016-19.1-5.858.423 2.744 2.11 10.026 9.339 10.29h.475l.264.369c1.371 1.794 4.062 5.435 4.642 6.068l-1.477 1.267z"
      ></path>
      <path
        fill="#E28B6D"
        d="M48.295 185.314c-1.002 0-1.899-.422-2.48-.897-1.371-1.108-10.551-8.337-10.551-8.337l1.213-1.53s9.233 7.282 10.605 8.39c.316.263 1.055.633 1.9.316 1.16-.475 2.057-2.163 2.426-4.854l1.952.264c-.633 4.379-2.321 5.909-3.64 6.384-.528.159-1.003.264-1.425.264zM39.538 163.574c-.844-.844-7.967-1.899-9.338-1.477l-.528-1.9c1.74-.475 10.393.422 11.554 2.375l-1.688 1.002z"
      ></path>
      <path
        fill="#E28B6D"
        d="M47.874 195.973c-2.849 0-6.383-2.322-6.911-2.691l-8.283-5.435 1.055-1.636 8.283 5.435c2.11 1.424 5.751 3.06 6.912 2.005.422-.369.58-.95.58-1.794-.053-2.427-2.005-5.963-3.588-7.282l1.267-1.53c1.952 1.636 4.22 5.751 4.273 8.759.053 1.372-.422 2.533-1.213 3.272-.686.686-1.478.897-2.375.897z"
      ></path>
      <path
        fill="#E28B6D"
        d="M41.805 202.674c-2.427 0-5.381-1.9-6.12-2.375l-7.28-4.749 1.054-1.635 7.281 4.801c1.847 1.214 4.96 2.639 5.91 1.794.316-.316.474-.791.474-1.477-.053-2.216-1.9-5.329-3.113-6.332l1.267-1.53c1.74 1.477 3.746 5.118 3.798 7.809 0 1.267-.369 2.269-1.108 2.955-.58.528-1.319.739-2.163.739z"
      ></path>
      <mask
        id="mask0_7466_162"
        style={{ maskType: "alpha" }}
        width="394"
        height="85"
        x="8"
        y="334"
        maskUnits="userSpaceOnUse"
      >
        <ellipse
          cx="204.502"
          cy="376.5"
          fill="#DE5AE4"
          rx="196.5"
          ry="42.5"
        ></ellipse>
      </mask>
      <g fill="#1F3C88" mask="url(#mask0_7466_162)">
        <path d="M219.713 321.608l-3.429 95.717a361.324 361.324 0 01-.264 7.704c-1.794 52.555-5.17 103-9.919 151.808-5.381 55.246-12.398 108.329-20.682 159.828h-54.342c-7.228-114.818-19.416-252.696 2.954-377.435l13.613-33.348 72.069-4.274z"></path>
        <path d="M294.421 364.612c13.612 113.975 25.43 241.616 20.207 360.603l-58.985 11.45c-16.092-99.252-44.266-226.893-47.853-341.976l-7.862-93.501 88.426 24.747 6.067 38.677z"></path>
      </g>
      <path
        fill="#F9B384"
        d="M226.466 87.169h-39.411v48.861h39.411V87.169z"
      ></path>
      <path
        fill="#fff"
        d="M186.79 117.245c14.403 19.999 32.764 11.24 39.623-2.216l46.85 208.215c-43.738 18.837-85.787 21.634-125.146-.739l24.903-197.134 13.77-8.126z"
      ></path>
      <path
        fill="#102048"
        d="M248.202 56.934c8.706-17.255 10.658-43.849-9.602-44.957-17.622-17.36-46.112-14.985-62.626 3.113-31.972-9.867-36.721 48.809 4.01 46.54l68.218-4.696zM187.055 87.169v25.961c11.66.264 21.42-4.063 27.382-11.503 0 0-11.502-8.02-20.524-14.458h-6.858z"
      ></path>
      <path
        fill="#F9B384"
        d="M242.821 39.785l-3.113 30.182c-4.801 13.35-10.657 22.795-18.096 28.757-7.651 6.174-17.569 8.126-26.697 5.33-7.808-2.375-14.192-8.179-16.83-16.041-1.425-4.327-1.741-9.234-.475-14.564.211-.844.369-1.688.422-2.532.422-3.483.053-6.965-.844-10.342-1.847-7.071-1.952-14.458-.633-22.057l35.349-11.186 30.917 12.453z"
      ></path>
      <path
        fill="#708CD5"
        d="M173.705 402.604c-18.308 4.01-39.728 3.641-63.576 0 5.857-53.241 13.876-104.371 26.222-151.228l-51.44-5.012c.474-8.285 1.582-16.833 3.27-25.592 1.9-9.867 4.643-19.998 8.125-30.34.106-.37.264-.739.37-1.108 12.398-35.934 34.979-63.003 90.43-72.237 17.358 82.737 16.091 205.26-13.401 285.517zM306.239 333.639c3.113 18.679 5.856 37.358 8.705 56.776-17.78 6.332-39.253 8.654-63.892 7.546-10.288-33.137-17.305-67.699-21.315-103.422 0-.158 0-.264-.053-.369-6.331-56.776-10.446-116.719-3.218-179.088 22.687 1.636 45.954 8.548 67.744 22.531 7.492 4.802 13.506 11.872 17.569 20.209 15.194 31.344 22.317 58.94 28.173 87.434l-47.694 19.365a1344.74 1344.74 0 013.957 17.254c3.904 17.624 7.122 34.668 10.024 51.764z"
      ></path>
      <path
        fill="#708CD5"
        d="M147.325 231.272l-6.384 51.975-2.954 3.957-14.615 19.313c-5.961 7.862-14.086 11.766-22.317 11.819-8.864.053-17.674-4.327-23.742-13.191l-25.852-37.834c12.715-16.99 24.955-34.297 28.49-56.881 16.672 19.47 21.632 26.752 21.632 26.752l45.742-5.91z"
      ></path>
      <path
        fill="#102048"
        d="M175.71 50.918c13.085-.633 26.222-3.43 39.359-10.29 4.168 7.969 13.982 18.416 28.385 14.512l1.583-8.707 1.108-14.774-43.422-13.878-34.03 11.187 7.017 21.95z"
      ></path>
      <path
        fill="#1F3C88"
        d="M306.238 333.638c-27.699-2.691-54.026-15.46-76.502-39.099l-.316-.317.264-.053 66.583-12.294c3.851 17.624 7.069 34.667 9.971 51.763z"
        opacity="0.4"
      ></path>
      <path
        fill="#1F3C88"
        d="M80.763 209.843l-1.536 1.204 28.497 36.391 1.537-1.204-28.498-36.391z"
      ></path>
      <path
        fill="#1F3C88"
        d="M283.763 188.163l9.813 56.354s-17.516 11.609-22.898 2.111c-4.168-7.44 1.319-31.713 13.085-58.465z"
        opacity="0.4"
      ></path>
      <path
        fill="#F9B384"
        d="M182.833 228.212l-11.924.844c-1.319.106-2.427 1.055-2.744 2.427l-.105.317c-.58 2.163-.158 4.432 1.108 6.174l2.163 2.954c.369.475.264 1.161-.158 1.531-1.9.949-3.271 2.796-3.324 4.854 0 .528 0 1.055.105 1.53.422 2.005 1.847 3.641 3.694 4.169l1.16.316c.159.053.211.212.159.37l-1.689 2.796c-1.266 2.164.581 6.28 2.374 7.757.37.58 1.53.844 2.164.897l5.276.739c2.426.316 4.748 1.213 6.911 2.427 10.605 6.226 23.584 5.593 36.563 4.96l-1.003-34.087c-8.864-6.807-18.993-12.558-30.759-17.043l-27.962-19.893c-6.226 2.691-2.375 10.289 17.991 25.961z"
      ></path>
      <path
        fill="#708CD5"
        d="M339.951 245.255l4.115 17.413c5.382 22.584-11.554 44.113-32.816 41.633l-100.877-12.242 7.703-57.515 121.875 10.711z"
      ></path>
      <path
        fill="#1F3C88"
        d="M136.476 187.189l-14.091 119.214 1.938.23 14.092-119.215-1.939-.229zM218.147 233.543l-.165 1.945 75.701 6.408.165-1.945-75.701-6.408z"
      ></path>
      <path
        fill="#E28B6D"
        d="M187.688 244.042c-.581-.053-14.193-1.478-16.62-2.164l.528-1.899c1.899.527 12.398 1.688 16.303 2.11l-.211 1.953zM189.481 256.969c-.581-.053-14.615-1.477-17.042-2.163l.528-1.9c1.899.528 12.715 1.689 16.725 2.111l-.211 1.952z"
      ></path>
      <path
        fill="#F9B384"
        d="M233.852 77.302c24.691 3.693 27.962-35.618 1.741-19.893l-1.741 19.893z"
      ></path>
      <path
        fill="#E28B6D"
        d="M239.55 66.59a9.082 9.082 0 01-.106-3.958c.264-1.319.95-2.638 1.952-3.693 1.003-1.003 2.48-1.742 4.01-1.636 1.53.053 2.955 1.003 3.535 2.269l-.633.475c-.897-.792-1.899-1.108-2.902-1.003-1.002.053-1.899.58-2.638 1.32-.738.738-1.319 1.688-1.741 2.743-.369 1.056-.58 2.216-.739 3.483h-.738z"
      ></path>
      <path
        fill="#102048"
        d="M191.644 61.313c0 2.533-1.425 4.59-3.219 4.59-1.793 0-3.218-2.057-3.218-4.59 0-2.532 1.425-4.59 3.218-4.59 1.794 0 3.219 2.058 3.219 4.59zM215.702 58.833c0 2.533-1.424 4.59-3.218 4.59s-3.218-2.057-3.218-4.59 1.424-4.59 3.218-4.59 3.218 2.057 3.218 4.59zM201.721 57.408c-.211 2.322-1.055 4.591-2.058 6.649-.949 2.058-2.321 4.063-3.271 5.857-.95 1.9-1.213 3.64 0 5.17 1.161 1.584 3.06 2.797 4.907 4.17l-.422.79c-2.058-.843-4.327-1.688-6.068-3.64-.844-.95-1.477-2.374-1.477-3.8 0-1.371.475-2.637 1.002-3.745 1.161-2.217 2.48-3.852 3.588-5.805 1.161-1.9 2.216-3.799 2.955-5.857l.844.211z"
      ></path>
      <path
        fill="#fff"
        d="M194.492 86.852l17.305-4.908s-.052 7.81-8.336 9.71c-4.748 1.055-8.969-4.802-8.969-4.802z"
      ></path>
      <path
        fill="#FC8879"
        d="M185.893 79.465c0 4.485-3.429 8.179-7.809 8.548-1.424-4.327-1.741-9.234-.474-14.563.211-.844.369-1.689.422-2.533 4.432.317 7.861 4.01 7.861 8.548zM231.613 74.657a8.6 8.6 0 10-9.866 7.112 8.6 8.6 0 009.866-7.112z"
        opacity="0.4"
      ></path>
      <path
        fill="#1F3C88"
        d="M125.641 292.27l-2.269 14.247c-5.962 7.862-14.087 11.767-22.317 11.82 8.072-32.979 27.276-56.776 27.276-56.776l-2.69 30.709z"
        opacity="0.4"
      ></path>
      <path
        fill="#1F3C88"
        d="M108.23 246.522l-20.048-25.75c1.899-9.867 4.642-19.999 8.125-30.341.105-.369.263-.738.369-1.108 3.799 7.071 12.715 27.016 11.554 57.199z"
        opacity="0.2"
      ></path>
      <path
        className="animated wow lamp"
        fill="#FBC343"
        d="M49.626 68.278c0-13.455-11.66-24.22-25.483-22.69-10.34 1.161-18.73 9.446-19.996 19.735-.897 7.018 1.478 13.508 5.698 18.204 3.377 3.694 5.065 8.601 5.065 13.614 0 6.543 5.329 11.82 11.818 11.82 6.543 0 11.819-5.33 11.819-11.82 0-5.171 2.057-10.078 5.434-14.036a22.117 22.117 0 005.645-14.827z"
      ></path>
      <path
        className="animated wow lamp"
        fill="#102048"
        d="M26.781 112.866c4.168 0 7.598-3.114 8.125-7.124h-16.25c.528 4.01 3.957 7.124 8.125 7.124z"
      ></path>
      <path
        className="animated wow fadeIn"
        data-wow-delay="1.8s"
        fill="#fff"
        d="M19.162 64.93c-1.477 4.116-4.326 6.913-6.331 6.174-2.005-.739-2.427-4.643-.95-8.812 1.478-4.116 4.327-6.912 6.331-6.174 2.005.74 2.427 4.697.95 8.812z"
        opacity="0.8"
      ></path>
      <path
        className="animated wow lines"
        data-wow-delay="1s"
        fill="#FBC343"
        d="M27.519 37.832h-.58a2.004 2.004 0 01-2.005-2.005V23.005c0-1.108.896-2.005 2.005-2.005h.58c1.108 0 2.005.897 2.005 2.005v12.822c0 1.056-.897 2.005-2.005 2.005zM9.897 41.579l-.58.37c-.897.633-2.11.369-2.744-.528L.348 32.134c-.633-.897-.37-2.11.527-2.744l.58-.37c.898-.633 2.111-.369 2.744.528l6.279 9.287c.58.897.316 2.11-.58 2.744zM43.874 41.209l.58.37c.897.58 2.11.369 2.743-.528l6.068-8.97c.58-.898.37-2.111-.528-2.744l-.58-.37a2.015 2.015 0 00-2.743.528l-6.068 8.97c-.58.95-.37 2.163.528 2.744z"
      ></path>
    </svg>
  );
}

export default Icon;
