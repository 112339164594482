import clsx from "clsx";
import React from "react";
import style from "./style.module.scss"

interface UseCardProps {
    image: string,
    text: string,
    delay: number
}

const UseCard = (props: UseCardProps) => {
    return <div>
        <div
            className={clsx(style.wrapper, "animated wow slideInUp")}
            data-wow-delay={`.${props.delay}s`}
        >
            <img src={props.image} alt="" className={style.img}/>
            <p className={style.text}>{props.text}</p>
        </div>
    </div>
}
export default UseCard