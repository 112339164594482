import React from "react";

import WikiWide from "../../assets/image/wiki_wide.svg"
import WikiMobile from "../../assets/image/wiki_mobile.svg"
import style from "./style.module.scss"

const Wiki = () => {
    const wdth = window.innerWidth
    return <div className={style.wiki_opportunities} id={"wiki"}>
       <a href="https://wiki.justdoluck.club/wiki/Opportunities_of_SAAS_JDL_Marketing_Platform">
           {wdth > 747 && <img src={WikiWide}/>}
           {wdth <= 747 && <img src={WikiMobile}/>}
       </a>
    </div>

}
export default Wiki