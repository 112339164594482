import React, {FC, useState} from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import {ArticleType} from "../../mock";
import Modal from "../modal";
import {ArticleModal} from "./modals/ArticleModal";

type Props = {
  articles: ArticleType[];
  title: string;
};

export const Articles: FC<Props> = ({ articles, title }: Props) => {
  const [open, setOpen] = useState("");
  const [openModal, setOpenModal] = useState({ open: false, id: 0 });

  return (
    <div className={styles.article}>
      <ArticleModal
        open={openModal.open}
        onClose={() => setOpenModal({ open: false, id: openModal.id })}
        id={openModal.id}
      />
      <Modal open={!!open} onClose={() => setOpen("")}>
        <div className={styles.modal}>
          <div dangerouslySetInnerHTML={{ __html: open }} />
        </div>
      </Modal>
      <div className="container">
        <div
          className={clsx(styles.heading, "animated wow fadeInLeft")}
          data-wow-offset="50"
          data-wow-delay="0.2s"
        >
          <div>
              <h2 className="h2">
                  {title}
              </h2>
              <p className="f25">
                  Gathering insight, thoughts, and intentions into text "collections” </p>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        {articles.map((i, id) => (
          <div
            className={clsx(styles["list-item"], "animated wow fadeInUp")}
            data-wow-offset="50"
            data-wow-delay={`0.${3 + id}s`}
            key={i.id}
          >
            <img src={i.image} alt="" className={styles["list-item__img"]} />

            <div className={styles["list-item__bottom"]}>
              <h3 className={styles["list-item__title"]}>{i.title}</h3>
              <p className={styles["list-item__desc"]}>{i.desc}</p>
              <button
                className={styles.button}
                onClick={() => window.open(i.link, "_blank")}
              >
                Read
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
