import clsx from "clsx";
import styles from "../../style.module.scss";
import {IconArrDown} from "../../../../assets";
import React, {useRef, useState} from "react";

interface IPriceTableDesktop {
    setCalcExample: (calc: boolean)=>void,
    servicePackets: any[]
}

export const PriceTableMobile = (props: IPriceTableDesktop)=>{

    const refTo = (ref: React.RefObject<HTMLDivElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView();
        }
    };

    const [freeOpen, setFreeOpen] = useState(true);

    const refFree = useRef<HTMLDivElement>(null);


    return <>
        <div className={clsx(styles["table-mob-wrap"], "container")}>
            <div className={styles["table-mob__label"]}>Free+</div>
            <div className={styles["table-mob"]} ref={refFree}>
                <div className={styles["table-mob-row"]}>
                    <div className={clsx(styles["table-mob-col"], styles.bnone)}>
                        <div className={clsx(styles.text, styles.flex)}>
                            Cost of service package <span>($/1 month)</span>
                        </div>
                        <br/>
                        <div className={styles["table-mob-head"]}>
                            <p className={clsx(styles.price, styles.center)}>$ 0</p>
                            <a
                                href="https://justdoluck.com/user/login" target="_blank"
                                className={clsx(styles.btn, styles["btn--outline"])}
                            >
                                Get started!
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    className={clsx(
                        styles["table-mob-row"],
                        styles["table-mob-row--bg"]
                    )}
                >
                    <div className={clsx(styles["table-mob-col"], styles.flex)}>
                        <p>
                            Accounting period
                        </p>
                        <span
                            className={styles.help}
                            data-for="tbl"
                            data-tip="Payment time for exceeding the number of participants in the raffles"
                            data-iscapture="true"
                        >
                    ?
                  </span>
                    </div>
                    <div className={styles["table-mob-col"]}>
                        <span>30 days</span>
                    </div>
                </div>
                <div className={styles["table-mob-row"]}>
                    <div className={clsx(styles["table-mob-col"], styles.flex)}>
                        <p>
                            Free limit on the number of participants in one game
                        </p>
                        <span
                            className={styles.help}
                            data-for="tbl"
                            data-tip="Maximum number of participants within one raffle"
                            data-iscapture="true"
                        >
                    ?
                  </span>
                    </div>
                    <div className={styles["table-mob-col"]}>
                        <span>&#60;500</span>
                    </div>
                </div>
                {!freeOpen && (
                    <div
                        className={clsx(
                            styles["table-mob-row"],
                            styles["table-mob-row--bg"]
                        )}
                    >
                        <div className={styles["table-mob-col"]}>
                            <div
                                className={styles.more}
                                onClick={() => setFreeOpen(true)}
                            >
                                <div className={styles.more__label}>Show all</div>
                                <div className={styles.more__icon}>
                                    <IconArrDown />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div
                    className={clsx(
                        styles["table-mob--acc"],
                        freeOpen && styles.open
                    )}
                >
                    <div
                        className={clsx(
                            styles["table-mob-row"],
                            styles["table-mob-row--bg"]
                        )}
                    >
                        <div className={clsx(styles["table-mob-col"], styles.flex)}>
                            <p>
                                Integration ito streaming technology
                            </p>
                            <span
                                className={styles.help}
                                data-for="tbl"
                                data-tip="Ability to connect Youtube-stream while raffle is going"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                        <div className={styles["table-mob-col"]}>
                            <span>yes</span>
                        </div>
                    </div>

                    <div
                        className={clsx(
                            styles["table-mob-row"],
                            styles["table-mob-row--bg"]
                        )}
                    >
                        <div className={clsx(styles["table-mob-col"], styles.flex)}>
                            <p>Integration into the streaming technology raffle</p>
                            <span
                                className={styles.help}
                                data-for="tbl"
                                data-tip="The number of external links that can be placed within one raffle"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                        <div className={styles["table-mob-col"]}>
                            <span>yes</span>
                        </div>
                    </div>
                    <div className={styles["table-mob-row"]}>
                        <div className={clsx(styles["table-mob-col"], styles.flex)}>
                            <p>Public  API JDL interface</p>
                            <span
                                className={styles.help}
                                data-for="tbl"
                                data-tip="Integration with the API of our service to develop even more attractive marketing tools"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                        <div className={styles["table-mob-col"]}>
                            <span>yes</span>
                        </div>
                    </div>
                    <div
                        className={clsx(
                            styles["table-mob-row"],
                            styles["table-mob-row--bg"]
                        )}
                    >
                        <div className={clsx(styles["table-mob-col"], styles.flex)}>
                            <p>Creating your own Groups</p>
                            <span
                                className={styles.help}
                                data-for="tbl"
                                data-tip="Create your own communities within our platform. Another opportunity to hold the attention of your audience."
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                        <div className={styles["table-mob-col"]}>
                            <span>yes</span>
                        </div>
                    </div>
                    <div className={styles["table-mob-row"]}>
                        <div className={clsx(styles["table-mob-col"], styles.flex)}>
                            <p>Exceeding the limit on players in one game</p>
                            <span
                                className={styles.help}
                                data-for="tbl"
                                data-tip="If you have reached the limit of the number of participants in one raffle, then we open the opportunity for you to exceed this limit"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                        <div className={styles["table-mob-col"]}>
                            <span>yes</span>
                        </div>
                    </div>
                    <div
                        className={clsx(
                            styles["table-mob-row"],
                            styles["table-mob-row--bg"]
                        )}
                    >
                        <div className={clsx(styles["table-mob-col"], styles.flex)}>
                            <p>
                                Cost of exceeding the limit on players in one game{" "}
                                <span>(%/person) </span>{" "}
                                <span
                                    className={styles.lnk}
                                    onClick={() => props.setCalcExample(true)}
                                >
                        calculation example
                      </span>
                            </p>
                            <span
                                className={styles.help}
                                data-for="tbl"
                                data-tip="The cost of each additional participant over the limit"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                        <div className={styles["table-mob-col"]}>
                            <span>$0.01</span>
                        </div>
                    </div>
                    <div className={styles["table-mob-row"]}>
                        <div className={clsx(styles["table-mob-col"], styles.flex)}>
                            <p>Payment for exceeding the limit on players</p>
                            <span
                                className={styles.help}
                                data-for="tbl"
                                data-tip="Payment time for exceeding the number of participants in the raffles"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                        <div className={styles["table-mob-col"]}>
                            <span>At the end of the period
                            </span>
                        </div>
                    </div>
                    <div className={clsx(
                        styles["table-mob-row"],
                        styles["table-mob-row--bg"]
                    )}>
                        <div className={clsx(styles["table-mob-col"], styles.flex)}>
                            <p>Ability to create raffles at custom time</p>
                            <span
                                className={styles.help}
                                data-for="tbl"
                                data-tip="Raffles with non-zero minutes"
                                data-iscapture="true"
                            >
                      ?
                    </span>
                        </div>
                        <div className={styles["table-mob-col"]}>
                            <span>yes</span>
                        </div>
                    </div>
                    {freeOpen && (
                        <div
                            className={clsx(
                                styles["table-mob-row"],
                                styles["table-mob-row--bg"]
                            )}
                        >
                            <div className={styles["table-mob-col"]}>
                                <div
                                    className={styles.more}
                                    onClick={() => {
                                        setFreeOpen(false);
                                        refTo(refFree);
                                    }}
                                >
                                    <div className={styles.more__label}>Show less</div>
                                    <div
                                        className={styles.more__icon}
                                        style={{ transform: "rotate(180deg)" }}
                                    >
                                        <IconArrDown />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
}