import styles from "../../clock/style.module.scss";
import {uses1, uses2, uses3, uses4, uses5, uses6, uses7, uses8} from "../../../assets";
import React from "react";
import UseCard from "./UseCard";

const usefullFor = [
    {
        image: uses1,
        text: "Brands",
    },
    {
        image: uses2,
        text: "Advertising agencies",
    },
    {
        image: uses3,
        text: "Bloggers",
    },
    {
        image: uses4,
        text: "Companies providing emotional and gaming content",
    },
    {
        image: uses5,
        text: "Gamification company",
    },
    {
        image: uses6,
        text: "Сelebrities",
    },
    {
        image: uses7,
        text: "Streamers",
    },
    {
        image: uses8,
        text: "Event company",
    },
]
const UsefullFor = () => {

    return <>
        <div className={styles.blocks}>
            {usefullFor.map((v, i) => <UseCard image={v.image} text={v.text} key={v.text} delay={i + 1}/>)}
        </div>
    </>
}
export default UsefullFor