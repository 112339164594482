const numberFormat = (value:number, amount?:number):string => {
    let dataString = new Intl.NumberFormat('en-US').format(value);
    let afterCol = dataString.split('.')[1];
    if (!afterCol) {
        return dataString;
    }
    while (afterCol?.length < (amount || 0)) {
        afterCol += '0';
    }
    dataString = dataString.split('.')[0] + '.' + afterCol;
    return dataString;
};
export default numberFormat