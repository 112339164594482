import style from "./style.module.scss"
// @ts-ignore
import ReactPlayer from 'react-player'

interface IVideoWrapperProps {
    title: string,
    description: string,
    link: string
}

const VideoWrapper = (props: IVideoWrapperProps) => {
    return <div className={style.wrapper}>
        <div className={style.title}>
            {props.title}
        </div>
        <div className={style.description}>
            {props.description}
        </div>
        <div className={style.video}>
            <ReactPlayer url={props.link} />
        </div>
    </div>
}
export default VideoWrapper