import MainPage from "./pages/MainPage";
// @ts-ignore
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Calc from "./pages/calc/Calc";
import Calc2 from "./pages/calc/Calc2";
import React, {Suspense} from 'react';


const AgentsLazy = React.lazy(() => import('./pages/Partners/Partners'));
const AgentsWrapper = () => {
    return (
        <Suspense fallback={<div />}>
            <AgentsLazy />
        </Suspense>
    );
};


const App = ()=>{
    // @ts-ignore
    return <>
        <div>
                <BrowserRouter>
                    <Switch>
                        <Route path="*/calc" component={Calc} />
                        <Route path="*/calc2" component={Calc2} />
                        <Route path={"*/partners_section"} component={AgentsWrapper}/>
                        <Route path={'*'} component={MainPage} />
                    </Switch>
                </BrowserRouter>
        </div>
    </>
}
export  default  App